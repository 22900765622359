import React from 'react'
import { Card, Row, Col } from 'react-bootstrap'

export default function Canvas() {
  return (
    <Row>
      <Col md={12}>
        <Card>
          <Card.Body>
            <p className='text-center'>🔨 Under Construction 🛠️</p>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  )
}
