import { asyncForEach, wsfunction } from '../helper'
import { URL_BARCA, TOKEN_BARCA, TOKEN_CONMEBOL, URL_CONMEBOL, URL_OPENCAMPUS, TOKEN_OPENCAMPUS, URL_FEHGRA, TOKEN_FEHGRA } from '../const'
import { consultarReporteCalificaciones, consultarReporteAcceso } from './consultarRCAction'
import { usuarioWithProductCurrent } from './usuarioActions'
import { OCTAVIO_URL, OCTAVIO_TOKEN } from '../const'

export const ENROLMENT_DATA_START = 'ENROLMENT_DATA_START'
export const ENROLMENT_DATA = 'ENROLMENT_DATA'
export const ENROLMENT_DATA_END = 'ENROLMENT_DATA_END'

export const enrolment_data = (instancia, cursos, datatable, hasFilterNote, valorFilter, operadorFilter, cursoCurrent, sectionCurrent, sectionStartDate, sectionEndDate, withoutActivity, usuarioFullData,productos, sections, productoCurrent, cursosAll, selectPartner, partnerSelect, partnersClientes,  compras, users) => async(dispatch) => {

  const parterCliente = partnersClientes;

  dispatch({
    type: ENROLMENT_DATA_START,
    payload: {loading: true}
  })

  let URL = null;
  let TOKEN = null;
  let newArrCursos = [];

  if (instancia == 101) {
    URL = URL_BARCA;
    TOKEN = TOKEN_BARCA;
  }
  if (instancia == 110) {
    URL = URL_CONMEBOL;
    TOKEN = TOKEN_CONMEBOL;
  }
  if (instancia == 111) {
    URL = URL_FEHGRA
    TOKEN = TOKEN_FEHGRA
  }
  if (instancia == 119) {
    URL = URL_OPENCAMPUS;
    TOKEN = TOKEN_OPENCAMPUS;
  }
  const requestOptions = {
    method: 'POST',
  };

  console.log('URL:', URL)
  console.log('TOKEN:', TOKEN)
  if (URL !== null && TOKEN !== null) {

    console.log('Consultar cursos: ', cursos)
    console.log('datatable: ', datatable)
    let arrCursos = null
    if (productoCurrent == 0) {

      // *** Consultar todos los productos
      console.log('Consultar todos los productos')
      //generateCursosProductos(usuarioFullData, productos);
      const arrData = []
      const arrDataFullInfo = []
      let myIDSectionMoodle = []
      for (const producto of productos) {
        
        console.log('producto.id:' , producto.id)

        // ***
        // *** Result Start ***
        try {
          const data = producto
          console.log('data: ', data)
          // *** Get getArrMoodle Start ***
          const optionSelectIdiomas = [];
          let getArrMoodle = [];
          let arrIdiomas = data.idiomas
          console.log('data.cliente: ', data.cliente)
          console.log('data.idsMoodle: ', data.idsMoodle)
          let arrMoodle = JSON.parse(data.idsMoodle);
          arrIdiomas.forEach((element, index) => {
            if (arrMoodle[element] !== undefined) {

              const value = arrMoodle[element].join();
              const arrMoodleID = arrMoodle[element];
              arrMoodleID.forEach( ID => {
                
                getArrMoodle.push({producto: producto.id, id: ID})
              });
              optionSelectIdiomas.push({
                value,
                name: element.toUpperCase(),
              });
            } 
          });
          console.log('getArrMoodle:', getArrMoodle)
          // *** Get getArrMoodle End ***

          // *** Fill Select Curso And Get getArrCursosID Start ***
          const newArrMoodle = cursosAll.filter( curso => {
            let add = false;
            let producto = null
            getArrMoodle.forEach( moodleID => {
              if (curso.id === moodleID.id) {
                add = true;
                producto = moodleID.producto
              }
            })
            if (add) {
              curso['producto'] = producto 
              return curso;
            }
          });

          console.log('newArrMoodle: ', newArrMoodle)
          
          let arrCursosID = [];
          
          newArrMoodle.forEach( moodle => {
            console.log('moodle: ', moodle)
            arrCursosID.push(moodle.id);
            arrData.push(
              {
                producto: moodle.producto,
                curso:moodle.name,
                curso_id:moodle.id
              }
            ); 
          });

          

          sections.forEach( item => {
            arrCursosID.forEach( moodleID => {
              if (item.categoryid === moodleID) {
                arrData.forEach( element => {
                  if (element.curso_id == item.categoryid) {
                    if (element.secciones === undefined) {
                      element.secciones = [item]
                      myIDSectionMoodle.push(item.id)
                    } else {
                      element.secciones.push(item)
                      myIDSectionMoodle.push(item.id)
                    }
                  }
                });
              }
            })
          });
          // *** Result End ***
        } catch (error) {
          
        }
        // ***
        
      }

      console.log('arrData: ', arrData)
      datatable = arrData;
      arrCursos = myIDSectionMoodle;
      
    } else {
      arrCursos = cursos;
    }
    

    console.log('arrCursos: ', arrCursos)
    console.log('datatable: ', datatable)
    const arrEmailMoodle = []
    let num = 1;
    await asyncForEach(arrCursos, async(ID) => {
      console.log('num: ', num)
      num ++;

      await fetch(`${URL}?wstoken=${TOKEN}&wsfunction=${wsfunction.core_enrol_get_enrolled_users}&courseid=${ID}&moodlewsrestformat=json`, requestOptions)
        .then( response => response.json())
        .then( async(response) => {
          if (response.length > 0) {

            //console.log('students: ', response)

            const newResponse = await Promise.all(
              response.map( async(item) => {
                let myPartner = '';
                //console.log('item: ', item)
  
                if (arrEmailMoodle.filter(e => e.email === item.email).length > 0) {

                  for (const arrEmailMoodleIte of arrEmailMoodle) {
                    if (arrEmailMoodleIte.eamil === item.email) {
                      if (arrEmailMoodleIte.compras.length > 0) {
                        console.log('arrProductos: ', arrEmailMoodle.compras)
                        for (const dataCompraItem of arrEmailMoodle.compras) {
                          try {
                            let parseItemCompraProducto = JSON.parse(dataCompraItem.productos)
                            console.log('parseItemCompraProducto: ', parseItemCompraProducto)
                            if (parseItemCompraProducto.length > 0) {
                              for (const compraProductoItem of parseItemCompraProducto) {
                                console.log('Item Producto: ', compraProductoItem.producto)
    
                                for (const datatableAItem of datatable) {
                                  console.log(`${datatableAItem.producto} == ${compraProductoItem.producto}`)
                                  if (datatableAItem.producto == compraProductoItem.producto) {
                                    for (const datatableAItemSections of datatableAItem.secciones) {
                                      console.log(`${datatableAItemSections.id} == ${ID}`)
                                      if (datatableAItemSections.id == ID) {
                                        for (const selectPartnerIte of selectPartner) {
                                          console.log(`${selectPartnerIte.id} == ${dataCompraItem.partner}`)
                                          if (selectPartnerIte.id == dataCompraItem.partner) {
                                            myPartner = selectPartnerIte.nombre
                                          }
                                        }
                                      }
                                    }
                                  }      
                                }
    
                              }
                            }
                          } catch (e) {
                            
                          }
                        }
                        //const arrProductos = JSON.parse(data.productos)
                      }
                      
                    }
                  }


                  
                } else {

                  let userData = null;
                  for (const userItem of users) {
                    if (userItem.email == item.email) {
                      userData = userItem
                    }
                  }
                  
                  // ***
                  const data = userData
                  
                  let IDUsuario = 0;
                  try {
                    IDUsuario = data.id;
                  } catch (e) {
                    IDUsuario = 0;
                  }


                  if (IDUsuario !== 0) {

                    // *** Check Compras
                    let dataCompra = []
                    for (const compraItem of compras) {
                      if (compraItem.usuario == IDUsuario) {
                        dataCompra.push(compraItem)
                      }
                    }
                    
                    // *** ==============
                    if (dataCompra.length > 0) {
                      //console.log('arrProductos: ', dataCompra)
                      for (const dataCompraItem of dataCompra) {
                        try {
                          let parseItemCompraProducto = JSON.parse(dataCompraItem.productos)
                          //console.log('parseItemCompraProducto: ', parseItemCompraProducto)
                          if (parseItemCompraProducto.length > 0) {
                            for (const compraProductoItem of parseItemCompraProducto) {
                              //console.log('Item Producto: ', compraProductoItem.producto)

                              for (const datatableAItem of datatable) {
                                //console.log('datatableAItem: ', datatableAItem)
                                //console.log(`${datatableAItem.producto} == ${compraProductoItem.producto}`)
                                if (datatableAItem.producto == compraProductoItem.producto) {
                                  for (const datatableAItemSections of datatableAItem.secciones) {
                                    //console.log(`${datatableAItemSections.id} == ${ID}`)
                                    if (datatableAItemSections.id == ID) {
                                      for (const selectPartnerIte of selectPartner) {
                                        //console.log(`${selectPartnerIte.id} == ${dataCompraItem.partner}`)
                                        if (selectPartnerIte.id == dataCompraItem.partner) {
                                          myPartner = selectPartnerIte.nombre
                                        }
                                      }
                                    }
                                  }
                                }      
                              }

                            }
                          }
                        } catch (e) {
                          
                        }
                      }
                      //const arrProductos = JSON.parse(data.productos)
                    }
                    // *** ==============

                  }
                  // ***
                }
  
                //console.log('arrEmailMoodle: ', arrEmailMoodle)
                item['partner'] = myPartner
                return item;
              })

            )

            console.log('newResponse: ', newResponse)

            newArrCursos.push({
              seccion_id: ID,
              students: newResponse
            })
          }
        
      });
    
    })

    let newArrGrade = [];

    await asyncForEach(arrCursos, async(ID) => {
      await fetch(`${URL}?wstoken=${TOKEN}&wsfunction=${wsfunction.gradereport_user_get_grade_items}&courseid=${ID}&moodlewsrestformat=json`, requestOptions)
        .then( (response) =>  {
          return response.json()
        })
        .then((data) => {
          
          newArrGrade.push({
            seccion_id: ID,
            grades: data,
          })
        })
    })

    const arrStudentID = [];
    
    for (const newArrCursosItem of newArrCursos) {
      console.log('newArrCursosItem ', newArrCursosItem)
      if (newArrCursosItem.students) {
        const myArrSTDS = newArrCursosItem.students;
        for (const itemStudent of myArrSTDS) {
          const ID = itemStudent.id
          if (!arrStudentID.includes(ID)) {
            arrStudentID.push(ID)
          }
        }
      }
    }

    console.log('arrStudentID', arrStudentID)
    
    const arrDataGradeFinalScore = [];

    for (const stdID of arrStudentID) {
      await fetch(`${URL}?wstoken=${TOKEN}&wsfunction=${wsfunction.gradereport_overview_get_course_grades}&userid=${stdID}&moodlewsrestformat=json`, requestOptions)
        .then( (response) =>  {
          return response.json()
        })
        .then((data) => {
          arrDataGradeFinalScore.push({student: stdID, grades: data.grades});
        })
    }

    console.log('arrDataGradeFinalScore: ', arrDataGradeFinalScore)

    dispatch({
      type: ENROLMENT_DATA,
      payload: {
        dataEnrolment: newArrCursos,
        dataGrade: newArrGrade
      }
    })

    dispatch({
      type: ENROLMENT_DATA_END,
      payload: {loading: false}
    })

    dispatch(consultarReporteCalificaciones(newArrCursos, newArrGrade, datatable, hasFilterNote, valorFilter, operadorFilter, cursoCurrent, sectionCurrent, sectionStartDate, sectionEndDate, withoutActivity, partnerSelect, selectPartner, arrDataGradeFinalScore))


  }

}

export const enrolment_data_acceso = (instancia, cursos, datatable, hasFilterNote, valorFilter, operadorFilter, cursoCurrent, sectionCurrent, sectionStartDate, sectionEndDate, withoutActivity, usuarioFullData,productos, sections, productoCurrent, cursosAll, selectPartner, partnerSelect, partnersClientes, compras, users) => async(dispatch) => {

  const parterCliente = partnersClientes;

  console.log('instancia:', instancia)

  dispatch({
    type: ENROLMENT_DATA_START,
    payload: {loading: true}
  })

  let URL = null;
  let TOKEN = null;
  let newArrCursos = [];

  if (instancia == 101) {
    URL = URL_BARCA;
    TOKEN = TOKEN_BARCA;
  }
  if (instancia == 110) {
    URL = URL_CONMEBOL;
    TOKEN = TOKEN_CONMEBOL;
  }
  if (instancia == 111) {
    URL = URL_FEHGRA
    TOKEN = TOKEN_FEHGRA
  }
  if (instancia == 119) {
    URL = URL_OPENCAMPUS;
    TOKEN = TOKEN_OPENCAMPUS;
  }
  const requestOptions = {
    method: 'POST',
  };

  if (URL !== null && TOKEN !== null) {

    console.log('Consultar cursos: ', cursos)
    console.log('datatable: ', datatable)
    let arrCursos = null
    if (productoCurrent == 0) {

      // *** Consultar todos los productos
      console.log('Consultar todos los productos')
      console.log('Productos: ', productos)
      //generateCursosProductos(usuarioFullData, productos);
      const arrData = []
      const arrDataFullInfo = []
      let myIDSectionMoodle = []
      let number = 0;
      for (const producto of productos) {
        
        console.log('producto.id:' , producto.id)

        // ***
        // *** Result Start ***
        try {
          const data = producto
          console.log('data: ', data)
          // *** Get getArrMoodle Start ***
          const optionSelectIdiomas = [];
          let getArrMoodle = [];
          let arrIdiomas = data.idiomas
          console.log('data.cliente: ', data.cliente)
          console.log('data.idsMoodle: ', data.idsMoodle)
          let arrMoodle = JSON.parse(data.idsMoodle);
          arrIdiomas.forEach((element, index) => {
            if (arrMoodle[element] !== undefined) {

              const value = arrMoodle[element].join();
              const arrMoodleID = arrMoodle[element];
              arrMoodleID.forEach( ID => {
                
                getArrMoodle.push({producto: producto.id, id: ID})
              });
              optionSelectIdiomas.push({
                value,
                name: element.toUpperCase(),
              });
            } 
          });
          console.log('getArrMoodle:', getArrMoodle)
          // *** Get getArrMoodle End ***

          // *** Fill Select Curso And Get getArrCursosID Start ***
          const newArrMoodle = cursosAll.filter( curso => {
            let add = false;
            let producto = null
            getArrMoodle.forEach( moodleID => {
              if (curso.id === moodleID.id) {
                add = true;
                producto = moodleID.producto
              }
            })
            if (add) {
              curso['producto'] = producto 
              return curso;
            }
          });

          console.log('newArrMoodle: ', newArrMoodle)
          
          let arrCursosID = [];
          
          newArrMoodle.forEach( moodle => {
            console.log('moodle: ', moodle)
            arrCursosID.push(moodle.id);
            arrData.push(
              {
                producto: moodle.producto,
                curso:moodle.name,
                curso_id:moodle.id
              }
            ); 
          });

          

          sections.forEach( item => {
            arrCursosID.forEach( moodleID => {
              if (item.categoryid === moodleID) {
                arrData.forEach( element => {
                  if (element.curso_id == item.categoryid) {
                    if (element.secciones === undefined) {
                      element.secciones = [item]
                      myIDSectionMoodle.push(item.id)
                    } else {
                      element.secciones.push(item)
                      myIDSectionMoodle.push(item.id)
                    }
                  }
                });
              }
            })
          });
          // *** Result End ***
        } catch (error) {
          
        }
        // ***
        
      }

      console.log('arrData: ', arrData)
      datatable = arrData;
      arrCursos = myIDSectionMoodle;
      
    } else {
      arrCursos = cursos;
    }
    

    console.log('arrCursos: ', arrCursos)
    console.log('datatable: ', datatable)
    const arrEmailMoodle = []
    let num = 1;
    await asyncForEach(arrCursos, async(ID) => {
      console.log('num: ', num)
      num ++;

      await fetch(`${URL}?wstoken=${TOKEN}&wsfunction=${wsfunction.core_enrol_get_enrolled_users}&courseid=${ID}&moodlewsrestformat=json`, requestOptions)
        .then( response => response.json())
        .then( async(response) => {
          if (response.length > 0) {

            //console.log('students: ', response)

            const newResponse = await Promise.all(
              response.map( async(item) => {
                let myPartner = '';
                //console.log('item: ', item)
  
                if (arrEmailMoodle.filter(e => e.email === item.email).length > 0) {

                  for (const arrEmailMoodleIte of arrEmailMoodle) {
                    if (arrEmailMoodleIte.eamil === item.email) {
                      if (arrEmailMoodleIte.compras.length > 0) {
                        console.log('arrProductos: ', arrEmailMoodle.compras)
                        for (const dataCompraItem of arrEmailMoodle.compras) {
                          try {
                            let parseItemCompraProducto = JSON.parse(dataCompraItem.productos)
                            console.log('parseItemCompraProducto: ', parseItemCompraProducto)
                            if (parseItemCompraProducto.length > 0) {
                              for (const compraProductoItem of parseItemCompraProducto) {
                                console.log('Item Producto: ', compraProductoItem.producto)
    
                                for (const datatableAItem of datatable) {
                                  console.log(`${datatableAItem.producto} == ${compraProductoItem.producto}`)
                                  if (datatableAItem.producto == compraProductoItem.producto) {
                                    for (const datatableAItemSections of datatableAItem.secciones) {
                                      console.log(`${datatableAItemSections.id} == ${ID}`)
                                      if (datatableAItemSections.id == ID) {
                                        for (const selectPartnerIte of selectPartner) {
                                          console.log(`${selectPartnerIte.id} == ${dataCompraItem.partner}`)
                                          if (selectPartnerIte.id == dataCompraItem.partner) {
                                            myPartner = selectPartnerIte.nombre
                                          }
                                        }
                                      }
                                    }
                                  }      
                                }
    
                              }
                            }
                          } catch (e) {
                            
                          }
                        }
                        //const arrProductos = JSON.parse(data.productos)
                      }
                      
                    }
                  }


                  
                } else {
                  

                  
                  let userData = null;
                  for (const userItem of users) {
                    if (userItem.email == item.email) {
                      userData = userItem
                    }
                  }

                  //console.log('userData: ', userData)

                  // ***
                  const data = userData
                  
                  let IDUsuario = 0;
                  try {
                    IDUsuario = data.id;
                  } catch (e) {
                    IDUsuario = 0;
                  }


                  if (IDUsuario !== 0) {
                    //console.log('IDUsuario: ', IDUsuario)
                    // *** Check Compras
                    let dataCompra = []
                    for (const compraItem of compras) {
                      if (compraItem.usuario == IDUsuario) {
                        dataCompra.push(compraItem)
                      }
                    }

                    // *** ==============
                    
                    if (dataCompra.length > 0) {
                      //console.log('arrProductos: ', dataCompra)
                      for (const dataCompraItem of dataCompra) {
                        try {
                          let parseItemCompraProducto = JSON.parse(dataCompraItem.productos)
                          //console.log('parseItemCompraProducto: ', parseItemCompraProducto)
                          if (parseItemCompraProducto.length > 0) {
                            for (const compraProductoItem of parseItemCompraProducto) {
                              //console.log('Item Producto: ', compraProductoItem.producto)

                              for (const datatableAItem of datatable) {
                                //console.log('datatableAItem: ', datatableAItem)
                                //console.log(`${datatableAItem.producto} == ${compraProductoItem.producto}`)
                                if (datatableAItem.producto == compraProductoItem.producto) {
                                  for (const datatableAItemSections of datatableAItem.secciones) {
                                    //console.log(`${datatableAItemSections.id} == ${ID}`)
                                    if (datatableAItemSections.id == ID) {
                                      for (const selectPartnerIte of selectPartner) {
                                        //console.log(`${selectPartnerIte.id} == ${dataCompraItem.partner}`)
                                        if (selectPartnerIte.id == dataCompraItem.partner) {
                                          myPartner = selectPartnerIte.nombre
                                        }
                                      }
                                    }
                                  }
                                }      
                              }

                            }
                          }
                        } catch (e) {
                          
                        }
                      }
                      //const arrProductos = JSON.parse(data.productos)
                    }
                    // *** ==============

                  }
                  // ***
                }
  
                //console.log('arrEmailMoodle: ', arrEmailMoodle)
                item['partner'] = myPartner
                return item;
              })

            )

            //console.log('newResponse: ', newResponse)

            newArrCursos.push({
              seccion_id: ID,
              students: newResponse
            })
          }
        
      });
    
    })

    let newArrGrade = [];

    dispatch({
      type: ENROLMENT_DATA,
      payload: {
        dataEnrolment: newArrCursos,
        dataGrade: newArrGrade
      }
    })

    dispatch({
      type: ENROLMENT_DATA_END,
      payload: {loading: false}
    })

    dispatch(consultarReporteAcceso(newArrCursos, newArrGrade, datatable, hasFilterNote, valorFilter, operadorFilter, cursoCurrent, sectionCurrent, sectionStartDate, sectionEndDate, withoutActivity, partnerSelect, selectPartner))


  }

}


