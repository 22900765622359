import React, { Component, Fragment } from 'react';
import Navigation from '../components/Navigation/Navigation';
import Loader from '../components/Global/Loader';
import LoaderSecondary from '../components/Global/LoaderSecondary';


class routeListReprogramacion extends Component {

    constructor(props) {
        super(props)
        this.state = {

            validation: "needs-validation",
            loading:{
                compras:    undefined,
                categorias: undefined,
            },
            modal:{
                open:       false,
                text:       null,
                error:      false,
                loading:    false,
                type:       null,
                reload:     false,
                continue:   false,
            },
            clientes:       false,
            clienteSelected:null,
            usuario:        null,
            enrolData:{
                courseActual:   null,
                courseNew:      null,
                fechaNew:       null,     
                role:           null,
                status:         null,
                producto:       null,
                categoria:      null,
            }
        }

        this.obtenerClientes    = this.obtenerClientes.bind(this);
        this.searchBar          = this.searchBar.bind(this);
        this.selecCliente       = this.selecCliente.bind(this);
        this.handleSeleccion    = this.handleSeleccion.bind(this);
        this.handleValidation   = this.handleValidation.bind(this);
        this.handleModal        = this.handleModal.bind(this);
        this.handleSeleccionCourse= this.handleSeleccionCourse.bind(this);
        this.handleContinue     = this.handleContinue.bind(this);
        this.buscarUsuario      = this.buscarUsuario.bind(this);
        this.obtenerCompras     = this.obtenerCompras.bind(this);
        this.comprasData        = this.comprasData.bind(this);
        this.productosData      = this.productosData.bind(this);
        this.itemData           = this.itemData.bind(this);
        this.reprogramarSecciones= this.reprogramarSecciones.bind(this);
        this.eliminarSecciones  = this.eliminarSecciones.bind(this);

    }

    componentDidMount() {
        this.obtenerClientes();
    }

    obtenerClientes() {
        this.setState({
            loading:{
                ...this.state.loading,
                cliente: true,
            }
        })
        if (localStorage.getItem('clientes') !== null) {
            this.setState({
                clientes: JSON.parse(localStorage.getItem('clientes')),
                loading:{
                    ...this.state.loading,
                    cliente: false,
                }
            })
        } else {
            let myHeaders = new Headers();
            myHeaders.append("Auth-Token", "zBKhfY9jXOUNVunScyIBltJJa3W1vg?HXsXPK5T1NCq1G!lXKN1ve9QOm25zIInZAQNgAK2VS7kTj7370NgqPg8w3DiQDb8AgtCCg6YNhU4wo45K!wEnOjPSnOtC8Rs");

            let requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            fetch(window.$UrlOctavio + "/clientes", requestOptions)
                .then(response => response.json())
                .then(result => {
                    let clientes = [];

                    for (let i = 0; i < result.length; i++) {
                        clientes.push({
                            'id': result[i].id,
                            'dominio': result[i].dominio,
                            'path_moodle': result[i].path_moodle,
                            'moodleToken': result[i].moodleToken
                        })
                    }
                    this.setState({
                        clientes: clientes,
                        loading:{
                            ...this.state.loading,
                            cliente: false,
                        }
                    })
                })
                .catch(error => console.log('error', error));
        }
    }

    route(){
        return (
            <div className="bg-light pt-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <label className="navigation-breadcrumb">Inicio / Reprogramaciones</label>
                                <h5>Reprogramacion de cursos</h5>
                            </div>
                        </div>
                    </div>
                </div>
        )
    }

    searchBar(){
        return (
            <div className="navbarstiky sticky-top bg-white mb-4 pb-3 pt-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                                <form action="" onSubmit={this.buscarUsuario} className={this.state.validation}>
                                    <div className="d-flex flex-column flex-xl-row flex-lg-row flex-md-row flex-sm-row justify-content-start align-items-center">
                                        {this.selecCliente()}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
        )
    }

    selecCliente() {
        if (this.state.clientes) {
            if (this.state.clientes.length === null || this.state.clientes.length === undefined) {
                return <LoaderSecondary></LoaderSecondary>
            } else {
                return <Fragment>
                    <select type="text" className="form-control mb-2" name="id_cliente" onChange={this.handleSeleccion} required>
                        <option value="">Selecciona el cliente</option>
                        {this.state.clientes.map(cliente => {
                            return <option key={`cliente${cliente.id}`} value={JSON.stringify(cliente)}>{`${cliente.id} - ${cliente.dominio}`}</option>
                        })}
                    </select>
                    <input className="form-control ml-2 mr-2 mb-2" name="usuario" type="email" placeholder="Email de usuario" onChange={this.handleSeleccion} required />
                    <button className="btn btn-primary mb-2" onClick={this.buscarUsuario}>
                        Buscar
                </button>
                </Fragment>
            }
        } else{
            return <LoaderSecondary></LoaderSecondary>
        }
    }

    handleSeleccion(e) {
        const { value, name } = e.target;
        switch(name){
            case 'usuario':
                this.setState({
                    usuario:{
                        ...this.state.usuario,
                        email: value,
                    }
                })
                break;
            case 'id_cliente':
                this.setState({
                    clienteSelected: JSON.parse(value),
                })
                break;
            default:
                this.setState({
                    [name]: value,
                })
                break;
        }
    }

    handleValidation() {
        if ( !this.state.clienteSelected || !this.state.usuario ) {
            return false;
        } else {
            return true;
        }
    }

    handleModal() {
        this.setState(prevState => ({
            modal:{
                ...this.state.modal,
                open: !prevState.modal.open,
            }
        }));
        if(this.state.modal.reload){
            this.buscarUsuario();
        }
    }

    handleSeleccionCourse(e) {

        const curso = JSON.parse(e.target.value);
        let selects = document.querySelectorAll('.start-dates');

        selects.forEach(select =>{
            if(Number(select.id) !== Number(curso.categoria)){
                select.selectedIndex = 0;
            }
        })

        this.setState({
            enrolData:{
                ...this.state.enrolData,
                courseActual: curso.idCourseActual,
                courseNew: curso.id,
                fechaNew: curso.inicio*1000,
                producto: curso.producto,
            }
        })
    }

    handleContinue(){
        setTimeout(() => {
            this.reprogramarSecciones()
        }, 500);
        this.setState({
            modal:{
                ...this.state.modal,
                continue:true,
                loading: true,
            },
        })
    }

    buscarUsuario(e) {
        if(e){
            e.preventDefault();
            e.stopPropagation();
        }

        if (this.handleValidation() === true) {
            this.setState({
                loading:{
                    compras: true,
                    categorias: undefined,
                },
                modal:{
                    open:       false,
                    text:       null,
                    error:      false,
                    loading:    false,
                    type:       null,
                    reload:     false,
                    continue:   false,
                },
                enrolData:{
                    courseActual:   null,
                    courseNew:      null,
                    fechaNew:       null,     
                    role:           null,
                    status:         null,
                    producto:       null,
                }
            })

            let myHeaders = new Headers();
            myHeaders.append("Auth-Token", "zBKhfY9jXOUNVunScyIBltJJa3W1vg?HXsXPK5T1NCq1G!lXKN1ve9QOm25zIInZAQNgAK2VS7kTj7370NgqPg8w3DiQDb8AgtCCg6YNhU4wo45K!wEnOjPSnOtC8Rs");
            myHeaders.append("cliente", this.state.clienteSelected.id);
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

            let requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            fetch(window.$UrlOctavio + "/usuarios?filter={\"email\":\"" + this.state.usuario.email + "\"}", requestOptions)
                .then(response => response.json())
                .then(result => {

                    if (result.length === 0) {
                        this.setState({
                            loading:{
                                ...this.state.loading,
                                compras: null,
                            }
                        })
                    } else {
                        this.setState({
                            usuario:{
                                ...this.state.usuario,
                                idOctavio:  result[0].id,
                                idMoodle:   result[0].idMoodle,
                                idCanvas:   result[0].idCanvas,
                            },
                        })
                        this.obtenerCompras();
                        this.getLogs();
                    }
                })

                .catch(error => {
                    this.setState({
                        loading:{
                            ...this.state.loading,
                            compras: null,
                        }
                    })
                    console.log('error', error)
                });

        } else {
            this.setState({
                validation: "needs-validation was-validated",
            });
        }
    }

    obtenerCompras() {

        this.setState({
            loading:{
                ...this.state.loading,
                compras: true,
            }
        })

        let myHeaders = new Headers();
        myHeaders.append("Auth-Token", "zBKhfY9jXOUNVunScyIBltJJa3W1vg?HXsXPK5T1NCq1G!lXKN1ve9QOm25zIInZAQNgAK2VS7kTj7370NgqPg8w3DiQDb8AgtCCg6YNhU4wo45K!wEnOjPSnOtC8Rs");
        myHeaders.append("cliente", this.state.clienteSelected.id);
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        let requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(window.$UrlOctavio + "/cursos/miscursos/" + this.state.usuario.idOctavio, requestOptions)
            .then(response => response.json())
            .then(result => {

                if (result.length === 0) {
                    this.setState({
                        loading:{
                            ...this.state.loading,
                            compras: null,
                        },
                        usuario:{
                            ...this.state.usuario,
                            compras:false,
                        }
                    })
                    
                } else {
                    let comprasNew = [];
                    let productos = [];
                    result.forEach(compra => {
                        if(compra.estado !== 'inactivo'){
                            comprasNew.push(compra);
                            compra.productos.forEach(producto =>{
                                let categorias = [];
                                let cursos = [];
                                producto.categoriasDB.forEach(categoria =>{
                                    categorias.push({
                                        id: categoria
                                    })
                                })
                                producto.cursos.forEach(curso=>{
                                    cursos.push(curso);
                                })
                                productos.push({
                                    id:             producto.id,
                                    cursosEnroled:  cursos,
                                    nombre:         producto.nombre,
                                    categorias:     categorias,
                                    idLms:          producto.idLms
                                })
                            })
                        }
                    });
                    this.setState({
                        loading:{
                            ...this.state.loading,
                            compras: false,
                        },
                        usuario:{
                            ...this.state.usuario,
                            compras:    comprasNew,
                            productos:  productos
                        }
                    });
                    this.obtenerCategorias();
                }
            })
            .catch(error => {
                this.setState({
                    loading:{
                        ...this.state.loading,
                        compras: null,
                    },
                    usuario:{
                        ...this.state.usuario,
                        compras:false,
                    }
                })
                console.log('error', error)
            });
    }

    async obtenerCategorias() {

        this.setState({
            loading:{
                ...this.state.loading,
                categorias: true,
            }
        })

        let productos = [];

        for( let producto of this.state.usuario.productos){
            
            let prod = {};
            prod.id = producto.id;
            prod.nombre = producto.nombre;
            prod.categorias = [];
            prod.idLms = producto.idLms;

            if(producto.idLms === 2){
                for( let categoria of producto.categorias){
                    prod.categorias.push(await this.getCategoriaAsync(producto,categoria));
                }
            }
            productos.push(prod);
        }

        this.setState({
            loading:{
                ...this.state.loading,
                categorias: false,
            },
            usuario:{
                ...this.state.usuario,
                productos: productos,
            }
        })

    }

    async postLog(usuario,cursos,tipo,cliente) {
        return new Promise((res,rej)=>{

            let finalData = {
                usuario: usuario,
                categoria: cursos.categoria,
                curso_viejo: cursos.cursoOld,
                curso_nuevo: cursos.cursoNew,
                tipo: tipo,
                cliente: cliente
            };

            var myHeaders = new Headers();
            myHeaders.append("Auth-Token", localStorage.getItem('token'));
            myHeaders.append("Content-Type", "application/json");
    
            var raw = JSON.stringify(finalData);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };
    
            fetch(window.$UrlApiDev + "/logsRepro", requestOptions)
                .then(response => response.json())
                .then(result => {
                    // console.log(result)
                    res(result);
                })
                .catch(error => {
                    console.log('error', error)
                    rej(error);
                });
        })
    }

    getCategoriaAsync(producto, categoria) {
        let requestOptions = {
            method: 'POST',
            redirect: 'follow'
        };

        let cliente = this.state.clienteSelected;
        let URL = `${cliente.dominio}/${cliente.path_moodle}/webservice/rest/server.php`;
        let TOKEN = `${cliente.moodleToken}`;
        let FUNCTION = `core_course_get_courses_by_field&field=category`;

        return new Promise((res,rej)=>{
            
            let categoriaNueva = {};
            categoriaNueva.cursosDisponibles = [];
            categoriaNueva.cursosEnroled = [];

            fetch(`https://${URL}?wstoken=${TOKEN}&moodlewsrestformat=json&wsfunction=${FUNCTION}&value=${categoria.id}`, requestOptions)
            .then(response => response.json())
            .then(result =>{       
                
                if(!result.errorcode){
                    result.courses.forEach(cursoMoodle=>{
                    let curso = {};
                    curso.id = cursoMoodle.id;
                    curso.nombreCorto = cursoMoodle.shortname;
                    curso.inicio = cursoMoodle.startdate;
                    curso.fin = cursoMoodle.enddate;
                    categoriaNueva.cursosDisponibles.push(curso);
                    });

                    producto.cursosEnroled.forEach(curso=>{
                        if(curso.category === categoria.id){
                            categoriaNueva.cursosEnroled.push(curso);
                        }
                    })

                    categoriaNueva.nombre = result.courses[0].categoryname;
                    categoriaNueva.id = categoria.id;
                    res(categoriaNueva);
                }else {
                    this.setState({
                        modal:{
                            ...this.state.modal,
                            open: true,
                            loading: 1,
                            text: 'Error al obtener las categorias del producto: '+result.errorcode,
                            error: true,
                        },
                        loading:{
                            ...this.state.loading,
                            categorias: null,
                        }
                    })
            
                    rej();
                }
            })
            .catch(error => {
                console.log('error', error)
                rej();
            });
        })
    }

    async reprogramarSecciones() {

        this.setState({
            modal:{
                ...this.state.modal,
                loading: true,
            }
        })

        let productoActual = null;
        this.state.usuario.productos.forEach(producto=>{
            if(producto.id === this.state.enrolData.producto){
                productoActual = producto;
            }
        })

        let existeFechaInicio = false;
        if(!!productoActual){
            productoActual.categorias.forEach(categoria => {
                categoria.cursosEnroled.forEach(curso=>{
                    let inicioPorCurso = new Date(curso.fechaInicio);
                    let fechaNewEnrolment = new Date(this.state.enrolData.fechaNew);
                    if(inicioPorCurso.getMonth() === fechaNewEnrolment.getMonth() && inicioPorCurso.getFullYear() === fechaNewEnrolment.getFullYear()){ //Revisar esta igualdad
                        existeFechaInicio = true;
                    }
                });
            });
        }
        
        if(!this.state.enrolData.courseNew){
            this.setState({
                modal:{
                    ...this.state.modal,
                    loading: 1,
                    text: 'Por favor, seleccione una nueva fecha de inicio',
                    error: true,
                }
            })
        }else if(!!existeFechaInicio && !this.state.modal.continue){
            this.setState({
                modal:{
                    ...this.state.modal,
                    loading: 2,
                    text: 'Ya existe un curso con inicio en el mismo mes. Desea continuar?',
                    error: true,
                }
            })
        }else {

            let requestOptions = {
                method: 'POST',
                redirect: 'follow'
            };

            let cliente = this.state.clienteSelected;
            let URL = `${cliente.dominio}/${cliente.path_moodle}/webservice/rest/server.php`;
            let TOKEN = `${cliente.moodleToken}`;
            let FUNCTION = `enrol_manual_enrol_users`;
            let courseId = this.state.enrolData.courseNew;
            let userId = this.state.usuario.idMoodle;
            let role = 5 //Por defecto estudiante

            if(!!this.state.enrolData.courseActual){
                await this.eliminarSecciones(false);
            }

            fetch(`https://${URL}?wstoken=${TOKEN}&moodlewsrestformat=json&wsfunction=${FUNCTION}&enrolments[0][userid]=${userId}&enrolments[0][courseid]=${courseId}&enrolments[0][roleid]=${role}`, requestOptions)
            .then(response => response.json())
            .then(result =>{  

                if(result === null){
                    this.setState({
                        modal:{
                            ...this.state.modal,
                            loading: 1,
                            text:    'Enrolado con exito',
                            reload:  true,
                            continue: false,
                            error:    false,
                        }
                    })
                    let cursos= {
                        categoria: this.state.enrolData.categoria ? this.state.enrolData.categoria : null,
                        cursoOld:  this.state.enrolData.courseActual ? this.state.enrolData.courseActual : null,
                        cursoNew:  this.state.enrolData.courseNew ? this.state.enrolData.courseNew : null,
                    }
                    this.postLog(this.state.usuario.idOctavio,cursos,'reprogramacion',this.state.clienteSelected.id);

                } else{
                    console.log('error', result);
                    this.setState({
                        modal:{
                            ...this.state.modal,
                            loading: 1,
                            text: 'Error al enrolar usuario',
                            error: true,
                            continue: false,
                        }
                    })
                }

            })
            .catch(error => {
                console.log('error', error);
                this.setState({
                    modal:{
                        ...this.state.modal,
                        loading: 1,
                        text: 'Error al enrolar usuario',
                        error: true,
                        continue: false,
                    }
                })
            });
        }        
    }

    eliminarSecciones(postLog=true) {

        return new Promise((res,rej)=>{
            
            this.setState({
                modal:{
                    ...this.state.modal,
                    loading: true,
                }
            })
    
            let requestOptions = {
                method: 'POST',
                redirect: 'follow'
            };
    
            let cliente = this.state.clienteSelected;
            let URL = `${cliente.dominio}/${cliente.path_moodle}/webservice/rest/server.php`;
            let TOKEN = `${cliente.moodleToken}`;
            let FUNCTION = `enrol_manual_unenrol_users`;
            let courseId = this.state.enrolData.courseActual;
            let userId = this.state.usuario.idMoodle;
    
            fetch(`https://${URL}?wstoken=${TOKEN}&moodlewsrestformat=json&wsfunction=${FUNCTION}&enrolments[0][userid]=${userId}&enrolments[0][courseid]=${courseId}`, requestOptions)
                .then(response => response.json())
                .then(result =>{  
    
                    if(result === null){
                        this.setState({
                            modal:{
                                ...this.state.modal,
                                loading: 1,
                                text:    'Eliminado con exito',
                                reload:  true,
                            }
                        })
                        let cursos= {
                            categoria: this.state.enrolData.categoria ? this.state.enrolData.categoria : null,
                            cursoOld:  this.state.enrolData.courseActual ? this.state.enrolData.courseActual : null,
                            cursoNew:  this.state.enrolData.courseNew ? this.state.enrolData.courseNew : null,
                        }
                        if(!!postLog){
                            this.postLog(this.state.usuario.idOctavio,cursos,'eliminacion',this.state.clienteSelected.id);
                        }
                        res();
    
                    } else{
                        console.log('error', result);
                        this.setState({
                            modal:{
                                ...this.state.modal,
                                loading: 1,
                                text: 'Error al elminar usuario',
                                error: true,
                            }
                        })
                        rej();
                    }
    
                })
                .catch(error => {
                    console.log('error', error);
                    this.setState({
                        modal:{
                            ...this.state.modal,
                            loading: 1,
                            text: 'Error al elminar usuario',
                            error: true,
                        },
                    })
                    rej();
                });
        })
    }

    getLogs () {
        var myHeaders = new Headers();
        myHeaders.append("Auth-Token", localStorage.getItem('token'));
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(window.$UrlApiDev + `/logsRepro/${this.state.usuario.idOctavio}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.error) {
                    console.log('error',result.error)
                } else {
                    this.setState({
                        usuario:{
                            ...this.state.usuario,
                            logs: result
                        }
                    })
                }
            })
            .catch(error => {
                console.log('error', error)
                
            });
    }

    getCantRepros(categoria) {

        let repros = this.state.usuario.logs.filter(log => Number.parseInt(log.categoria) === categoria && log.tipo === 'reprogramacion');
        console.log("repros", repros, categoria)
        return repros.length;

    }
    //RENDERIZADOS

    modalValidacion() {
        return (

            <div className="custom-modal">
                <div className="custom-modal-content">

                    <div className="custom-modal-header">
                        <div className="row d-flex justify-content-between align-items-center">
                            <div>
                                <h6>Reprogramar Alumno</h6>
                            </div>
                            <div>
                                <button className="btn btn-close" onClick={this.handleModal}>
                                    <i className="fa fa-times"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="right-body bg-white text-center">
                        {this.state.modal.text ?
                            <div>
                                {this.state.modal.error === true ?
                                    <i className="fa fa-times text-danger fa-5x ml-auto mr-auto mb-2 mt-1 d-block"></i>
                                    :
                                    <i className="fa fa-check text-sussess fa-5x ml-auto mr-auto mb-2 mt-1 d-block"></i>
                                }
                                <p>{this.state.modal.text}</p>
                            </div>
                        : 
                            this.state.modal.type === 'reprogramar' ?
                            <>
                                <p>¿Estás seguro de que deseas reprogramar a este alumno?</p>
                                <span>Esta accion eliminara el enrolment anterior, por lo que no se podran recuperar las notas o el progreso del alumno</span>
                            </>
                            :
                            this.state.modal.type === 'eliminar' ?
                            <>
                                <p>¿Estás seguro de que deseas eliminar a este alumno?</p>
                                <span>Esta accion eliminara el enrolment, por lo que no se podran recuperar las notas o el progreso del alumno</span>
                            </>
                            :
                                <></>
                        }
                    </div>

                    {this.buttonsModal()}

                </div>
            </div>
        )
    }

    buttonsModal() {
        if (this.state.modal.loading === false) {
            return (
                <div className="right-footer d-flex justify-content-center align-items-stretch">
                    <button className="btn btn-secondary mr-2" onClick={this.handleModal}>
                        Cancelar
                    </button>
                    {this.state.modal.type === 'reprogramar' ? 
                        <button className="btn btn-primary" onClick={this.reprogramarSecciones}>
                            Aceptar
                        </button>
                    :
                        <>
                        </>
                    }
                    {this.state.modal.type === 'eliminar' ? 
                        <button className="btn btn-primary" onClick={this.eliminarSecciones}>
                            Aceptar
                        </button>
                    :
                        <>
                        </>
                    }
                </div>
            )
        } else if (this.state.modal.loading === null) {
            return false
        } else if (this.state.modal.loading === 1) {
            return (
                <div className="right-footer d-flex justify-content-center align-items-stretch">
                    <button className="btn btn-secondary mr-2" onClick={this.handleModal}>
                        Cerrar
                </button>
                </div>
            )
        }else if (this.state.modal.loading === 2) {
            return (
                <div className="right-footer d-flex justify-content-center align-items-stretch">
                    <button className="btn btn-secondary mr-2" onClick={()=>{this.handleModal();this.setState({enrolData:{...this.state.enrolData,courseNew:null}})}}>
                        Cancelar
                    </button>
                    <button className="btn btn-primary" onClick={this.handleContinue}>
                        Aceptar
                    </button>
                </div>
            )
        }else {
            return (
                <div className="right-footer d-flex justify-content-center align-items-stretch">
                    <button disabled={true} className="btn btn-primary mr-2">
                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                        <span className="sr-only">Loading...</span>
                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                        <span className="sr-only">Loading...</span>
                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                        <span className="sr-only">Loading...</span>
                        Cargando. . .
                    </button>
                </div>
            )
        }
    }

    comprasData() {
        if (this.state.loading.compras === undefined) {  //Deja todo como esta
            return false
        } else {
            if (this.state.loading.compras === true) { //Renderiza un loader
                return (
                    <div className="box-white">
                        <Loader></Loader>
                    </div>
                )
            } else if (this.state.loading.compras === false) { //Renderiza las compras
                return(
                    <>
                        <h4 className="global-title mb-3">Cursos</h4>
                        {this.productosData()}
                    </>
                )
            } else {
                return <div className="box-white"><p>No se encontraron compras con el ususario seleccionado</p></div>
            }
        }
    }

    productosData() {
        return(
            <>
                {this.state.usuario.productos.map(producto=>{
                    return(
                        <div className="box-white p-4 mb-3" key={producto.id}>
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <h4 className="mb-4">{producto.nombre}</h4>
                                </div>
                                {producto.idLms === 2 ?
                                    <ul className="list-group">
                                        <li className="list-group-item d-flex align-items-center">
                                            <span className="col-lg-5">Curso</span>
                                            <span className="col-lg-1 d-flex justify-content-center">N° repros</span>
                                            <span className="col-lg-1 d-flex justify-content-center">Nota</span>
                                            <span className="col-lg-1 d-flex justify-content-center">Fecha Inicio</span>
                                            <span className="col-lg-2 d-flex justify-content-center">Seleccione nueva fecha</span>
                                            <span className="col-lg-2 d-flex justify-content-center">Accion</span>
                                        </li>
                                        {this.categoriasData(producto)}
                                    </ul>
                                :
                                    <p>Producto de Canvas, aun pendiente esta funcionalidad</p>
                                }
                            </div>
                        </div>
                    )
                })}
            </>
        )
    }

    categoriasData(producto) {
        if (this.state.loading.categorias === undefined) {  //Deja todo como esta
            return false
        } else {
            if (this.state.loading.categorias === true) { //Renderiza un loader
                return (
                    <div className="box-white">
                        <Loader></Loader>
                    </div>
                )
            } else if (this.state.loading.categorias === false) { //Renderiza las categorias
                return (
                    <>
                        {producto.categorias.map(categoria=>{   // TODO: En el return de etiquetas vacias de todos los <li> da error porque no tienen unique key
                            return (
                                <>                                  
                                    {categoria.cursosEnroled.length > 0 ?
                                        categoria.cursosEnroled.map(curso=>{
                                        curso.duplicado = categoria.cursosEnroled.length > 1 ? true : false;
                                        return(
                                            <>
                                                {curso.duplicado ?
                                                    <li className="list-group-item d-flex align-items-center border-danger" style={{marginBottom: '-0.5px',backgroundColor:'#FFC1CC'}} key={curso.id}>
                                                        <span className="col-5">{categoria.nombre}</span>
                                                        {this.itemData(producto.idLms, categoria, curso, producto.id)}
                                                    </li>
                                                    :
                                                    <li className="list-group-item d-flex align-items-center" style={{marginBottom: '-0.5px'}} key={curso.id}>
                                                        <span className="col-5">{categoria.nombre}</span>
                                                        {this.itemData(producto.idLms, categoria, curso, producto.id)}
                                                    </li>
                                                }
                                            </>
                                        )
                                    })
                                    :
                                    <li className="list-group-item d-flex align-items-center" style={{marginBottom: '-0.5px'}} key={categoria.id}>
                                        <span className="col-5">{categoria.nombre}</span>
                                        {this.itemData(producto.idLms, categoria,null,producto.id)}
                                    </li>
                                    }
                                </>
                            )
                        })}      
                    </>
                )
            } else {
                return <div className="box-white"><p>Error al mostrar las categorias</p></div>
            }
        }
    }

    itemData(idLms, categoria, curso=null, idProducto) {
        let fechaInicioActual = curso ? new Date (curso.fechaInicio) : new Date();
        return(
            <>
                <div className="col-1 d-flex justify-content-center">
                    <span className="badge badge-primary badge-pill">{this.getCantRepros(categoria.id)}</span>
                </div>
                {curso ?
                    <div className="col-1 d-flex justify-content-center">
                        <span className="badge badge-primary badge-pill">
                            {idLms === 2 ? curso.grade === undefined ? '-' : curso.grade
                            :
                            curso.calificaciones.final_score === undefined ? '-' : curso.grade
                            }
                        </span>
                    </div>
                :
                    <div className="col-1 d-flex justify-content-center">
                        <span className="badge badge-primary badge-pill">-</span>
                    </div>
                }
                
                
                {curso ?
                    <span className="col-1">{fechaInicioActual.getDate()}-
                        {fechaInicioActual.getMonth()+1}-
                        {fechaInicioActual.getFullYear()}
                    </span>
                :
                    <span className="col-1">No enrolado</span>
                }

                {curso ?
                    !curso.duplicado ?
                        <>
                            <div className="col-2">
                                <select type="text" className="form-control start-dates" id={`${categoria.id}`} name="new-start-date" onChange={this.handleSeleccionCourse}>
                                    <option value="" className="default">Selecciona la fecha</option>
                                    {categoria.cursosDisponibles.map(item => {
                                        item.producto = idProducto;
                                        item.idCourseActual = curso ? curso.id : null;
                                        item.categoria = categoria.id;
                                        let fechaInicio = new Date(item.inicio*1000);
                                        let fechaFin = new Date(item.fin*1000);
                                        let mostrarFecha = `${fechaInicio.getDate()}-${fechaInicio.getMonth()+1}-${fechaInicio.getFullYear()}`
                                        let today = new Date();

                                        if(fechaFin >= today && fechaInicioActual.getTime() !== fechaInicio.getTime()){
                                            /* console.log(fechaInicioActual,fechaInicio) */
                                            return <option key={`curso${item.id}`} value={JSON.stringify(item)}>{`${item.id} - ${mostrarFecha}`}</option>
                                        }else {
                                            return <></>
                                        }
                                    })}
                                </select>
                            </div>
                            <div className="col-2 d-flex justify-content-center">
                                <button className="btn btn-primary" onClick={() => this.setState({modal:{...this.state.modal,type:'reprogramar',open:true,error:false,text:null,loading:false},enrolData:{...this.state.enrolData,fechaActual:`${curso.fechaInicio}`,categoria: categoria.id}})}>Reprogramar</button>
                            </div>
                        </>
                    :
                        <>
                            <div className="col-2"><small>El alumno debe tener solo 1 curso de cada categoria</small></div>
                            <div className="col-2 d-flex justify-content-center">
                                <button className="btn btn-danger" onClick={() => this.setState({modal:{...this.state.modal,type:'eliminar',open:true,error:false,text:null,loading:false},enrolData:{...this.state.enrolData,categoria: categoria.id,courseActual:`${curso.id}`}})}>  Eliminar  </button>
                            </div> 
                        </>
                :
                    <>
                        <div className="col-2">
                            <select type="text" className="form-control start-dates" id={categoria.id} name="new-start-date" onChange={this.handleSeleccionCourse}>
                                <option value="">Selecciona la fecha</option>
                                {categoria.cursosDisponibles.map(item => {
                                    item.producto = idProducto;
                                    item.idCourseActual = curso ? curso.id : null;
                                    item.categoria = categoria.id;
                                    let fechaInicio = new Date(item.inicio*1000);
                                    let fechaFin = new Date(item.fin*1000);
                                    let mostrarFecha = `${fechaInicio.getDate()}-${fechaInicio.getMonth()+1}-${fechaInicio.getFullYear()}`
                                    let today = new Date();

                                    if(fechaFin >= today && fechaInicioActual.getTime() !== fechaInicio.getTime()){
                                        /* console.log(fechaInicioActual,fechaInicio) */
                                        return <option key={`curso${item.id}`} value={JSON.stringify(item)}>{`${item.id} - ${mostrarFecha}`}</option>
                                    }else {
                                        return <></>
                                    }
                                })}
                            </select>
                        </div>
                        <div className="col-2 d-flex justify-content-center">
                            <button className="btn btn-primary" onClick={() => this.setState({modal:{...this.state.modal,type:'reprogramar',open:true,error:false,text:null,loading:false},enrolData:{...this.state.enrolData,courseActual:null,categoria: categoria.id}})}>Reprogramar</button>
                        </div>
                    </>
                }        
            </>
        )
    }

    render() {
        return (
            <Fragment>
                <Navigation />
                {this.route()}
                {this.searchBar()}

                <div className="container mt-3">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-2">
                            {this.comprasData()}
                        </div>
                    </div>
                </div>

                {this.state.modal.open ?
                        this.modalValidacion()
                    :
                        <></>
                }

                {console.log(this.state)}
            </Fragment>
        )
    }

}

export default routeListReprogramacion;