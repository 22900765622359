export const CHANGE_NOTAS_SELECT = 'CHANGE_NOTAS_SELECT';
export const CHANGE_VALOR_SELECT = 'CHANGE_VALOR_SELECT';
export const CHANGE_WITHOUTACTIVITY_SELECT = 'CHANGE_WITHOUTACTIVITY_SELECT';

export const changeNotasSelect = (notas) => dispatch => {

  dispatch({
    type: CHANGE_NOTAS_SELECT,
    payload: {notas}
  })

}

export const changeValorSelect = (valor) => dispatch => {

  dispatch({
    type: CHANGE_VALOR_SELECT,
    payload: {valor}
  })

}

export const changeWithoutActivityCheck = (checking) => dispatch => {
    dispatch({
      type: CHANGE_WITHOUTACTIVITY_SELECT,
      payload: {withoutActivity: checking }
    })

}