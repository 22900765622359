const MODODEV = false;

export const OCTAVIO_URL = MODODEV ? 'https://octaviodev.capabiliaserver.com/api/v1' : 'https://octavio.capabiliaserver.com/api/v1';
export const OCTAVIO_TOKEN = MODODEV ?'G3y6nrMJNv6ZZnkuSLehuajOPnwJ9z1QbVsNL0J9Xyd6fDj4f4nZruEy9EzixdynaiIt2Zf61gn7BRIP8oTKr7RkMLn8wMktzcqWw5nrudk1qrygjvKaZI15k1NPDfH6LYGjYSYHF6PEzOjDeIoqeWdFGi1QW80OoLdGSjZnahNjbVPahXB0K3hAGG2KDEsQJ5SbuJDXwZ0cG64qbqQlPvQ6ZOatJoFKDKfNQTqXKpZeL6tbPtsfpQWbpKSUSFZmE7KHunovZwsFACwTFhDoOuU5LaSrNpNa9Qwhs0LAe8hqZfdhIxpXuvUHPiMoyfcZHVTCpID4uwr48OKSzD4ziNg9On98Gq0N8qoLhIIxS3QQtGNVXcdoxnoVQD9TcB3OuFhev0d8fPsHM7jJhsikW9u9hww24s35WVlTiJSdLm6WYv5XJyVZvmcjk8gEQ1G2ov9neMwia5KuQ3HchVpy0ukksUg9XBT8CIactb3CWhb7FhyZIfBq9GpCRIrZOJ9X' : 'G3y6nrMJNv6ZZnkuSLehuajOPnwJ9z1QbVsNL0J9Xyd6fDj4f4nZruEy9EzixdynaiIt2Zf61gn7BRIP8oTKr7RkMLn8wMktzcqWw5nrudk1qrygjvKaZI15k1NPDfH6LYGjYSYHF6PEzOjDeIoqeWdFGi1QW80OoLdGSjZnahNjbVPahXB0K3hAGG2KDEsQJ5SbuJDXwZ0cG64qbqQlPvQ6ZOatJoFKDKfNQTqXKpZeL6tbPtsfpQWbpKSUSFZmE7KHunovZwsFACwTFhDoOuU5LaSrNpNa9Qwhs0LAe8hqZfdhIxpXuvUHPiMoyfcZHVTCpID4uwr48OKSzD4ziNg9On98Gq0N8qoLhIIxS3QQtGNVXcdoxnoVQD9TcB3OuFhev0d8fPsHM7jJhsikW9u9hww24s35WVlTiJSdLm6WYv5XJyVZvmcjk8gEQ1G2ov9neMwia5KuQ3HchVpy0ukksUg9XBT8CIactb3CWhb7FhyZIfBq9GpCRIrZOJ9X';

export const TOKEN_CONMEBOL = MODODEV ? 'ebc4443995d7d90b4e1930d312e0cb89' : '956d8b0bf1442b40a078545608b5a66c'
export const URL_CONMEBOL = MODODEV ? 'https://conmebol.capabiliaserver.com/moodle/webservice/rest/server.php' : 'https://evolucion.conmebol.com/capabilia/webservice/rest/server.php';

export const TOKEN_BARCA = MODODEV ? '79534abe85d1a508d20262cf999b38f1' : '7089b7468fa1acfd1c114fdafdc0bf39'
export const URL_BARCA = MODODEV ? 'https://barca.capabiliaserver.com/capabilia/webservice/rest/server.php' : 'https://barcainnovationhub.com/capabilialms/webservice/rest/server.php';

export const TOKEN_AMERICANA = MODODEV ? '6a4724efb4494cef28283cabf8af5427' : ''
export const URL_AMERICANA = MODODEV ? 'https://americana.capabiliaserver.com/moodle/webservice/rest/server.php' : '';

export const TOKEN_OPENCAMPUS = MODODEV ? '' : '4bbccc5e0174e6e28336aab0940e6b80'
export const URL_OPENCAMPUS = MODODEV ? '' : 'https://opencampus.capabilia.org/capabilia/webservice/rest/server.php';

export const TOKEN_FEHGRA = MODODEV ? '' : 'f26494df7c6504f7cf634ce26a9cef9a'
export const URL_FEHGRA = MODODEV ? '' : 'https://www.fehgraeduca.org.ar/capabilia/webservice/rest/server.php';

export const INSTANCIA_ACTIVE = MODODEV ? [110,101, 111,108] : [101, 110, 111, 119];