import React, { Component, Fragment } from 'react';
import IconLarge from '../Global/IconLarge';
import SelectProducto from '../Global/SelectProducto';
import AddCriterios from '../Global/AddCriterios';
import HeaderForm from '../Global/HeaderForm';
import SelectTemplate from '../Global/SelectTemplate';
import $ from 'jquery';
import store from '../../store';
import Loader from '../Global/Loader';
import { OCTAVIO_TOKEN } from '../../const'


// var arrayCriterios = [];

class CertificadosForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            overlayShowHide: "",
            estado: "",
            mensaje: "",
            buttonDisable: false,
            validation: "needs-validation",
            data: {
                id_cliente: '',
                id_producto: '',
                id_template: '',
                id_partner: '',
                nombre_producto: '',
                tipo: '',
                fecha_inicio: '',
                fecha_fin: '',
                duracion: '',
                estado: '',
                criterios: [],
                productContent: ''
            },
            criterios: [],
            countCriterios: 0,
            modalShow: false,
            loading: true,
            eliminando: false,
            cambio: false,
            partners: [],
            partnerByClientes: [],
        };
        this.handleInput = this.handleInput.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleValidation = this.handleValidation.bind(this);
        this.sendForm = this.sendForm.bind(this);
        this.handleInputCriterios = this.handleInputCriterios.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    componentDidMount() {
        this.getData()
    }

    getData() {
        var myHeaders = new Headers();
        myHeaders.append("Auth-Token", localStorage.getItem('token'));

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(window.$UrlApiDev + "/certificacion/" + this.props.id, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result)
                this.setState({
                    loading: false,
                    data: {
                        id_cliente: result.id_cliente,
                        id_producto: result.id_producto,
                        id_template: result.id_template,
                        id_partner: result.id_partner,
                        nombre_producto: result.nombre_producto,
                        tipo: result.tipo,
                        idioma: result.idioma,
                        fecha_inicio: result.fecha_inicio,
                        fecha_fin: result.fecha_fin,
                        duracion: result.duracion,
                        estado: result.estado,
                        criterios: result.criterios,
                        productContent: result.content
                    },
                })
                this.searchPartnerByCliente(result.id_cliente)
            })
            .catch(error => console.log('error', error));
    }

    fillPartners() {

        let myHeaders = new Headers();
        myHeaders.append('Accept', 'application/json');
        myHeaders.append('Auth-Token', OCTAVIO_TOKEN);

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        };

        fetch(window.$UrlOctavio + "/partners", requestOptions)
            .then(response => response.json())
            .then(result => {

                const resultadoFilter = [];
                result.forEach(data => {

                    this.state.partnerByClientes.forEach(partnerCliente => {

                        if (data.id == partnerCliente.partner) {
                            resultadoFilter.push(data);
                        }
                    })
                });

                this.setState({
                    partners: resultadoFilter
                })

            })
            .catch(error => console.log('error', error));
    }

    createSelectItemsPartner() {
        let items = [];
        items.push(<option key={0} value={"0"}> Sin Partner </option>);
        this.state.partners.forEach(partner => {
            items.push(<option key={partner.id} value={partner.id}> {partner.nombre} </option>);
        })
        return items;
    }

    searchPartnerByCliente(cliente) {

        let myHeaders = new Headers();
        myHeaders.append('Accept', 'application/json');
        myHeaders.append('Auth-Token', OCTAVIO_TOKEN);

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        };

        fetch(window.$UrlOctavio + "/partners/clientes", requestOptions)
            .then(response => response.json())
            .then(result => {

                const partnerByClientesResultado = result.filter(data => data.cliente == cliente);
                this.setState({
                    partnerByClientes: partnerByClientesResultado,
                }, this.fillPartners())
            })
            .catch(error => console.log('error', error));
    }

    handleInput(e) {
        const { value, name } = e.target;
        this.setState({
            data: {
                ...this.state.data,
                [name]: value
            },
            cambio: true
        })
        // console.log(this.state.data)
    }

    handleInputCriterios(e) {
        // console.log(`nombre: ${name} valor: ${value}`)
        // return false;
        const { value, name } = e.target;
        this.setState(prevState => ({
            criterios: {
                ...prevState.criterios,
                [name]: value
            },
            cambio: true
        }));
    }
    handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();


        // Función que descompone un array de muchos elementos en arrays cada 3 elementos
        function groupByThree([a, b, c, ...rest]) {
            if (rest.length === 0) return [[a, b, c].filter(x => x !== undefined)]
            return [[a, b, c]].concat(groupByThree(rest))
        }

        var inputs = $('#criterios').find('.form-control');
        var arraysito = [];

        for (let i = 0; i < inputs.length; i++) {
            arraysito.push(
                {
                    [inputs[i].name]: inputs[i].value
                }
            )
        }

        // console.log(arraysito)

        var criterios = groupByThree(arraysito);
        var objetivoMayor = []
        for (let i = 0; i < criterios.length; i++) {
            var objetivo = {}
            for (let o = 0; o < criterios[i].length; o++) {
                Object.assign(objetivo, criterios[i][o])

            }
            objetivoMayor.push(objetivo)
        }


        this.setState({
            validation: "needs-validation was-validated",
            countCriterios: $('#criterios').find('input').length
        });

        if (this.handleValidation() === true) {
            if (this.state.cambio) {
                this.sendForm(objetivoMayor);
            }

        };
    }

    handleValidation() {
        if (!this.state.data.id_cliente || !this.state.data.id_producto || !this.state.data.id_template || !this.state.data.nombre_producto || !this.state.data.tipo || !this.state.data.estado) {
            return false;
        } else {
            return true;
        }
    }

    sendForm(criterios) {
        this.setState({
            buttonDisable: true
        })

        var myHeaders = new Headers();
        myHeaders.append("Auth-Token", localStorage.getItem('token'));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify(
            {
                "id_cliente": this.state.data.id_cliente,
                "id_producto": this.state.data.id_producto,
                "id_template": this.state.data.id_template,
                "idioma": this.state.data.idioma,
                "id_partner": this.state.data.id_partner,
                "nombre_producto": this.state.data.nombre_producto,
                "tipo": this.state.data.tipo,
                "fecha_inicio": this.state.data.fecha_inicio,
                "fecha_fin": this.state.data.fecha_fin,
                "duracion": this.state.data.duracion,
                "estado": this.state.data.estado,
                "content": this.state.data.productContent,
                "criterios": criterios
            }
        );

        // console.log(raw)
        // return false;

        var requestOptions = {
            method: 'PATCH',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(window.$UrlApiDev + "/certificacion/" + this.props.id, requestOptions)

            .then(r => r.json()
                .then(data => ({
                    status: r.status,
                    body: data
                })))
            .then(obj => {
                // console.log(obj)
                this.setState({
                    estado: obj.status,
                    mensaje: "Editado con éxito",
                    buttonDisable: false
                });

                store.dispatch({
                    type: "RERENDER_CERTIFICADOS",
                    rerenderCertificados: true
                })
            })
            .catch(error => {
                this.setState({
                    estado: 400,
                    mensaje: "Ocurrió un error al crear el certificado",
                    buttonDisable: false
                });
            });
    }

    handleDelete(event, param) {
        event.preventDefault();
        this.setState({
            eliminando: true
        })
        var myHeaders = new Headers();
        myHeaders.append("Auth-Token", localStorage.getItem('token'));
        myHeaders.append("Content-Type", "application/json");


        var requestOptions = {
            method: 'DELETE',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(window.$UrlApiDev + "/criterios/" + param, requestOptions)
            .then(r => r.json()
                .then(data => ({
                    status: r.status,
                    body: data
                })))
            .then(obj => {
                if (obj.status === 200) {
                    this.setState({
                        eliminando: false,
                        modalShow: false,
                        loading: true
                    })
                    this.getData();
                }
                console.log(obj)
            })
            .catch(error => {
                console.log(error)
            });
    }

    handleModal(event, param) {
        event.preventDefault();
        this.setState({
            modalShow: true,
            deletedSelected: param
        });
    }

    handleClose() {
        this.setState({
            modalShow: false,
            deletedSelected: null
        });
    }

    modal() {
        if (this.state.modalShow === true) {
            return (
                <div className="my-modal">
                    <div className="my-modal-content">
                        <div className="my-modal-header">
                            <div class="row d-flex justify-content-between align-items-center">
                                <h6>Eliminar criterio</h6>
                                <button onClick={this.handleClose} class="btn btn-close"><i class="fa fa-times"></i></button>
                            </div>
                        </div>
                        <div className="my-modal-body text-center">
                            <p>¿Estás seguro de eliminar el criterio?</p>
                        </div>
                        <div className="my-modal-footer d-flex justify-content-center align-items-stretch">
                            {this.buttonsActionModal()}
                        </div>
                    </div>
                </div>
            )
        } else {
            return false;
        }
    }

    buttonsActionModal() {
        if (this.state.eliminando === true) {
            return (
                <button disabled={this.state.buttonDisable} className="btn btn-primary mr-2" onClick={() => this.handleButtonShow()}>
                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                    <span className="sr-only">Loading...</span>
                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                    <span className="sr-only">Loading...</span>
                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                    <span className="sr-only">Loading...</span>
                    Eliminando...
                </button>
            )
        } else {
            return (
                <Fragment>
                    <button class="btn btn-primary mr-2" onClick={
                        (event) => this.handleDelete(event, this.state.deletedSelected)
                    }>Eliminar</button>
                    <button class="btn btn-secondary" onClick={this.handleClose}>Cancelar</button>
                </Fragment>
            )
        }

    }

    buttonsAction() {
        if (this.state.buttonDisable === false) {
            if (this.state.cambio) {
                return (
                    <div className="right-footer d-flex justify-content-center align-items-stretch">
                        <button disabled={this.state.buttonDisable} className="btn btn-primary mr-2" onClick={() => this.handleSubmit}>
                            Guardar cambios
                        </button>
                        <button disabled={this.state.buttonDisable} className="btn btn-secondary" onClick={() => this.props.overlayShowHide()}>
                            Cancelar
                        </button>
                    </div>
                )
            } else {
                return (
                    <div className="right-footer d-flex justify-content-center align-items-stretch">
                        <button disabled={true} className="btn btn-primary mr-2">
                            Guardar cambios
                        </button>
                        <button disabled={this.state.buttonDisable} className="btn btn-secondary" onClick={() => this.props.overlayShowHide()}>
                            Cancelar
                        </button>
                    </div>
                )
            }

        } else {
            return (
                <div className="right-footer d-flex justify-content-center align-items-stretch">
                    <button disabled={this.state.buttonDisable} className="btn btn-primary mr-2" onClick={() => this.handleButtonShow()}>
                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                        <span className="sr-only">Loading...</span>
                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                        <span className="sr-only">Loading...</span>
                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                        <span className="sr-only">Loading...</span>
                        Guardando...
                    </button>
                </div>
            )
        }
    }

    render() {
        if (this.state.estado === 400) {
            return (
                <div className="right-content bg-white shadow-lg">
                    <div className="right-header">
                        <div className="row d-flex justify-content-between align-items-center">
                            <div>
                                <h6>Editar certificado</h6>
                            </div>
                            <div>
                                <button className="btn btn-close" onClick={() => this.props.overlayShowHide()}>
                                    <i className="fa fa-times"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="right-body bg-white">
                        <div className="row">
                            <div className="col-lg-12 d-flex flex-column justify-content-center align-items-center">
                                <IconLarge icon="fa-times" size="4" color="text-danger" animation="shake" />
                                <p><b>Ocurrió un error al editar la certificación</b></p>
                                <p>Estado: {this.state.estado} <br></br> {this.state.mensaje.error}</p>
                            </div>
                        </div>
                    </div>
                    <div className="right-footer d-flex justify-content-center align-items-stretch">
                        <button disabled={this.state.buttonDisable} className="btn btn-primary" onClick={() => this.props.overlayShowHide()}>
                            Cerrar
                        </button>
                    </div>
                </div>
            )
        }
        else if (this.state.estado === 200) {
            return (
                <div className="right-content bg-white shadow-lg">
                    <div className="right-header">
                        <div className="row d-flex justify-content-between align-items-center">
                            <div>
                                <h6>Editar Certificado</h6>
                            </div>
                            <div>
                                <button className="btn btn-close" onClick={() => this.props.overlayShowHide()}>
                                    <i className="fa fa-times"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="right-body bg-white">
                        <div className="row">
                            <div className="col-lg-12 d-flex flex-column justify-content-center align-items-center">
                                <IconLarge icon="fa-check" size="4" color="text-success" animation="shake" />
                                <p><b>Se editó el certificado con éxito!</b></p>
                                <p>Estado: {this.state.estado} <br></br></p>
                            </div>
                        </div>
                    </div>
                    <div className="right-footer d-flex justify-content-center align-items-stretch">
                        <button disabled={this.state.buttonDisable} className="btn btn-primary" onClick={() => this.props.overlayShowHide()}>
                            Cerrar
                        </button>
                    </div>
                </div>
            )
        }
        else {
            return (
                <Fragment>
                    {this.modal()}
                    <form onSubmit={this.handleSubmit} className={this.state.validation} noValidate>
                        <div className="right-content bg-white shadow-lg">

                            <div className="right-header">
                                <div className="row d-flex justify-content-between align-items-center">
                                    <div>
                                        <h6>Editar certificado</h6>
                                    </div>
                                    <div>
                                        <button className="btn btn-close" onClick={() => this.props.overlayShowHide()}>
                                            <i className="fa fa-times"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="right-body bg-white">
                                <div className="row">
                                    <div className="col-lg-12">

                                        <HeaderForm title="Tipo de Certificación" help="Selecciona el tipo de certificado." position="bottom"></HeaderForm>
                                        <div className="form-group">
                                            <label htmlFor="tipo">Tipo de certificación</label>
                                            <select value={this.state.data.tipo} id="tipo" type="text" className="form-control" name="tipo" onChange={this.handleInput} required>
                                                <option value="">Selecciona un tipo</option>
                                                <option value="aprobacion">Aprobación</option>
                                                <option value="participacion">Participación</option>
                                                <option value="aprobacion_verificado">Aprobación Verificado</option>
                                                <option value="ponente">Ponente</option>
                                            </select>
                                            <div className="invalid-feedback">Selecciona un tipo de certificación</div>
                                            <div className="valid-feedback">Excelente!</div>
                                        </div>

                                        <div className="divider mt-5 mb-5"></div>
                                        <HeaderForm title="Template" help="El template corresponde a la plantilla visual de cada certificado." position="bottom"></HeaderForm>
                                        <SelectTemplate selected={this.state.data.id_template} change={this.handleInput}></SelectTemplate>

                                        <div className="divider mt-5 mb-5"></div>
                                        <div className="form-group">
                                            <HeaderForm title="Contenido del Certificado" help="Solo para metrodora" position="bottom"></HeaderForm>
                                            <label htmlFor="productContent">Contenido del Certificado</label>
                                            <textarea value={this.state.data.productContent} className="form-control" onChange={this.handleInput} id="productContent" name="productContent" />
                                        </div>

                                        <div className="divider mt-5 mb-5"></div>

                                        <HeaderForm title="Idioma" help="Selecciona el idioma." position="bottom"></HeaderForm>
                                        <div className="form-group">
                                            <label htmlFor="idioma">Idioma</label>
                                            <select id="idioma" type="text" className="form-control" name="idioma" onChange={this.handleInput} required>
                                                <option value="">Selecciona un idioma</option>
                                                <option value="es">Español</option>
                                                <option value="en">Inglés</option>
                                                <option value="pt">Portugués</option>
                                                <option value="ca">Catalán</option>
                                                <option value="ar">Árabe</option>
                                                <option value="ru">Ruso</option>
                                            </select>
                                            <div className="invalid-feedback">Selecciona un idioma</div>
                                            <div className="valid-feedback">Excelente!</div>
                                        </div>

                                        <div className="divider mt-5 mb-5"></div>
                                        <HeaderForm title="Producto" help="Selecciona el cliente y luego el producto el cual será configurado para este certificado." position="bottom"></HeaderForm>
                                        <SelectProducto selectedCliente={this.state.data.id_cliente} selectedPrducto={this.state.data.id_producto} change={this.handleInput}></SelectProducto>

                                        <div className="divider mt-5 mb-5"></div>
                                        <HeaderForm title="Criterios" help="Selecciona el cliente y luego el producto el cual será configurado para este certificado." position="bottom"></HeaderForm>

                                        <AddCriterios lms={this.state.producto} header={<HeaderForm title="Criterios" help="Agrega criterios que serán utilizados para verificar la entrega del certificado." position="bottom"></HeaderForm>} tipo={this.state.data.tipo} change={this.handleInputCriterios}></AddCriterios>


                                        <div className="divider mt-5 mb-5"></div>
                                        <HeaderForm title="Datos generales" help="Corresponden a datos generales de la certificación." position="bottom"></HeaderForm>
                                        <div className="form-group">
                                            <label htmlFor="nombre_producto">Nombre de la certificación</label>
                                            <input value={this.state.data.nombre_producto} id="nombre_producto" type="text" className="form-control" name="nombre_producto" placeholder="Nombre de la certificación" onChange={this.handleInput} required></input>
                                            <div className="invalid-feedback">Ingresa el nombre</div>
                                            <div className="valid-feedback">Excelente!</div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="id_partner">Partner (Opcional)</label>
                                            <select value={this.state.data.id_partner !== null ? this.state.data.id_partner : '0'} type="text" className="form-control" name="id_partner" onChange={this.handleInput}>
                                                {this.createSelectItemsPartner()}
                                            </select>
                                        </div>
                                        <div className="invalid-feedback">Selecciona un la duración</div>
                                        <div className="valid-feedback">Excelente!</div>
                                        <div className="form-group">
                                            <label htmlFor="duracion">Duración (Opcional)</label>
                                            <input value={this.state.data.duracion} id="duracion" type="number" className="form-control" name="duracion" placeholder="Duracción en meses" onChange={this.handleInput}></input>
                                            <div className="invalid-feedback">Ingresa la dureación</div>
                                            <div className="valid-feedback">Excelente!</div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-xl-6 col-lg-6 col-sm-12 col-md-12 col-12">
                                                <label htmlFor="fecha_inicio">Fecha de inicio (Opcional)</label>
                                                <input value={this.state.data.fecha_inicio} id="fecha_inicio" type="date" className="form-control" name="fecha_inicio" placeholder="Contraseña" onChange={this.handleInput}></input>
                                                <div className="invalid-feedback">Ingresa la fecha de inicio</div>
                                                <div className="valid-feedback">Excelente!</div>
                                            </div>
                                            <div className="form-group col-xl-6 col-lg-6 col-sm-12 col-md-12 col-12">
                                                <label htmlFor="fecha_fin">Fecha de fin (Opcional)</label>
                                                <input value={this.state.data.fecha_fin} id="fecha_fin" type="date" className="form-control" name="fecha_fin" placeholder="Contraseña" onChange={this.handleInput}></input>
                                                <div className="invalid-feedback">Ingresa la fecha de fin</div>
                                                <div className="valid-feedback">Excelente!</div>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label>Estado</label>
                                        </div>

                                        <div className="custom-control custom-radio custom-control-inline">
                                            {/* {console.log(this.state.data.estado)} */}
                                            <input checked={this.state.data.estado && this.state.data.estado === "1" && true} id="activo" type="radio" name="estado" onChange={this.handleInput} value="1" className="custom-control-input" required />
                                            <label className="custom-control-label" htmlFor="activo">Activo</label>
                                        </div>
                                        <div className="custom-control custom-radio custom-control-inline">
                                            <input checked={this.state.data.estado && this.state.data.estado === "0" && true} id="inactivo" type="radio" name="estado" onChange={this.handleInput} value="0" className="custom-control-input" />
                                            <label className="custom-control-label" htmlFor="inactivo">Inactivo</label>

                                        </div>
                                        <div className="invalid-feedback">El estado es requerido</div>
                                        <div className="valid-feedback">Excelente!</div>
                                    </div>
                                </div>
                            </div>
                            {this.buttonsAction()}
                        </div>
                    </form>
                </Fragment>
            )
        }
    }
}


export default CertificadosForm;