import React, { useEffect } from 'react'
import { Card, Row, Col, Form, Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import { fetchInstancia, changeInstanciaSelect } from '../../redux/instanciaActions'
import { changeProductoSelect } from '../../redux/productoActions'
import { changeIdiomaSelect } from '../../redux/idiomaActions'
import { changeCursoSelect } from '../../redux/cursoActions'
import { changeSectionSelect, changeSectionStartDate, changeSectionEndDate } from '../../redux/sectionActions'
import { changeNotasSelect, changeValorSelect, changeWithoutActivityCheck } from '../../redux/notasActions'
import { changePartnerSelect, fillSelectPartner } from '../../redux/partnerActions'
import { enrolment_data } from '../../redux/enrolmentAction'
import FillOption from './FillOption'
import Swal from 'sweetalert2'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { utils, write } from "xlsx";
import { saveAs } from 'file-saver';
//import ReactExport from "react-export-excel"

// const ExcelFile = ReactExport.ExcelFile
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

function MoodleFiltros({dispatch, loading, instancias, instanciaCurrent, productos, compras, users, productoCurrent, cursosIDWithProducto, cursoCurrent, partners, partnerData, partnerSelect, partnersClientes, idiomas, idiomaCurrent, cursos, cursosByProducto, sections, secciones, seccionesByProducto, sectionCurrent, sectionStartDate, sectionEndDate, usuarioFullData, withoutActivity, notas, valor, dataEnrolment, dataGrade, datatable, dataTableCertificado, headerTable}) {

  useEffect(() => {
    dispatch(fetchInstancia());
    dispatch(fillSelectPartner());
  },[])

  // *** Consultar Handler
  const consultarHandler = () => {
    console.log('*** ================ ***')
    console.log('*** Consultar Moodle ***')
    console.log('*** ================ ***')

    // *** =============== ***
    // *** Validar Filtros ***
    // *** =============== ***

    if(instanciaCurrent == 0){
      console.log('Por favor seleccione una instancia.')
      Swal.fire({
        icon: 'warning',
        title: 'Oops...',
        text: 'Por favor seleccione una instancia.',
      })
      return false
    }

    if (sectionStartDate == null || sectionStartDate == '') {
      Swal.fire({
        icon: 'warning',
        title: 'Oops...',
        text: 'Por favor seleccione una fecha inicio.',
      })
      return false 
    } 
    
    if (sectionEndDate == null || sectionEndDate == '') {
      Swal.fire({
        icon: 'warning',
        title: 'Oops...',
        text: 'Por favor seleccione una fecha fin.',
      })
      return false
    }

    // if(productoCurrent == 0){
    //   console.log('Por favor seleccione un producto.')
    //   Swal.fire({
    //     icon: 'warning',
    //     title: 'Oops...',
    //     text: 'Por favor seleccione un producto.',
    //   })
    //   return false
    // }

    // *** Get Value Filter Note Start
    let operadorFilter = notas
    let valorFilter = valor
    let hasFilterNote = operadorFilter == '0' || valorFilter == '0' ? false : true;
    // *** Get Value Filter Note End

    // *** Get Value Filter Parter Start
    const selectPartnerReportMoodle = '0'
    // *** Get Value Filter Parter End

    // *** Enrolement And Grade
    dispatch(enrolment_data(instanciaCurrent, secciones, datatable, hasFilterNote, valorFilter, operadorFilter, cursoCurrent, sectionCurrent, sectionStartDate, sectionEndDate, withoutActivity, usuarioFullData,  productos, sections, productoCurrent, cursos, selectPartner, partnerSelect, partnersClientes, compras, users))
  }

  // *** Generate Excel
  const generateExcel = () => {
    console.log('generateExcel')
    let wb = utils.book_new()
    wb.Props = {
      Title: 'Reporte de Calificaciones',
      Subject: 'Reporte Moodles',
      Author: 'AdminCapa',
      CreatedDate: new Date()
    }
    wb.SheetNames.push('Reporte')
    let checkSectionCurrent = sectionCurrent;
    if (typeof checkSectionCurrent === 'string' || checkSectionCurrent instanceof String) {
      checkSectionCurrent = parseInt(checkSectionCurrent);
    }
    let ws_data = []
    // *** Fill Header
    ws_data = [['Student Name',' STUDENT EMAIL', 'Student SISID', 'Course', 'Section', 'START DATE', 'DIAGNOSTIC', 'ACTIVITY', 'ACTIVITY', 'ACTIVITY', 'ACTIVITY', 'AUTOEVALUACION', 'AUTOEVALUACION', 'AUTOEVALUACION', 'AUTOEVALUACION', 'FINAL', 'FINAL SCORE', 'PARTNER']] 
     

    // *** Fill Body

    for (const item of dataTableCertificado) {
      ws_data.push([
        item.fullNameStudent, 
        item.emailStudent,
        item.idStudent,
        item.course,
        item.section,
        item.startDate,
        item.diagnostic,
        item.activity1,
        item.activity2,
        item.activity3,
        item.activity4,
        item.autoevaluation1,
        item.autoevaluation2,
        item.autoevaluation3,
        item.autoevaluation4,
        item.final,
        item.finalScore,
        item.partner,
      ])
    }
    
    let ws = utils.aoa_to_sheet(ws_data)
    ws['!cols'] = [{ width: 30}, { width: 30 }, { width: 30 }, { width: 30 },{ width: 30 },{ width: 30 }, { width: 30 },{ width: 30 }, { width: 30 }, { width: 30 }];
    wb.Sheets['Reporte'] = ws;
    let wbout = write(wb, {bookType: 'xlsx', type: 'binary'})
    let date = new Date()
    let millisec = date.getMilliseconds();
    let day = date.getDate()
    let month = date.getMonth() + 1
    let year = date.getFullYear()
    const nameFile = `reporte_calificaicones_${day}_${month}_${year}_${millisec}.xlsx`;
    saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), nameFile);    
  }

  const s2ab = (s) => {
    let buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
    let view = new Uint8Array(buf);  //create uint8array as viewer
    for (let i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
    return buf;
  }

  

  // *** Generate PDF
  const generatePDF = () => {
    console.log('generatePDF')
    const unit = "pt";
    const size = "A4";
    const orientation = "portrait";

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);
   
    const title = "Reporte de Calificaciones";
    const headers = [["STUDENT NAME", 'STUDENT EMAIL', "STUDENT SISID", "COURSE", "SECTION", "START DATE", "FINAL", "FINAL SCORE", "PARTNER"]];

    const data = dataTableCertificado.map( item => [
      item.fullNameStudent, 
      item.emailStudent, 
      item.idStudent,
      item.course,
      item.section,
      item.startDate,
      item.final,
      item.finalScore,
      item.partner,
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data
      
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    let date = new Date()
    let millisec = date.getMilliseconds();
    let day = date.getDate()
    let month = date.getMonth() + 1
    let year = date.getFullYear()
    doc.save(`reporte_calificaicones_${day}_${month}_${year}_${millisec}.pdf`)
  }

  const clearInputDate = () => {
    dispatch(changeSectionStartDate(''))
  } 
  
  const clearInputDateEnd = () => {
    dispatch(changeSectionEndDate(''))
  } 


  const selectPartner = partnerData;

  return (
    <Card>
      <Card.Body>
        <Row>
          <Col md={2}>
            <div className='form-group'>
              <label>Instancias</label>
              <Form.Control onChange={(e) => dispatch(changeInstanciaSelect(e.target.value))} as="select" disabled={loading}>
                <FillOption data={instancias} defaultOptionText={'Seleccione su instancia'} type="instancia" />
              </Form.Control>
            </div>
          </Col>
          <Col md={2}>
            <div className='form-group'>
              <label>Start Date</label>
              <Form.Control value={sectionStartDate} onChange={(e) => dispatch(changeSectionStartDate(e.target.value))} type="date" disabled={loading}></Form.Control>
              { sectionStartDate.length > 0 ? <button onClick={clearInputDate} style={{border: '0px solid white',marginTop: '0.3em' }} className='float-right'>X</button> : null}
            </div>
          </Col>
          <Col md={2}>
            <div className='form-group'>
              <label>End Date</label>
              <Form.Control value={sectionEndDate} onChange={(e) => dispatch(changeSectionEndDate(e.target.value))} type="date" disabled={loading}></Form.Control>
              { sectionEndDate.length > 0 ? <button onClick={clearInputDateEnd} style={{border: '0px solid white',marginTop: '0.3em' }} className='float-right'>X</button> : null}
            </div>
          </Col>
          <Col md={3}>
            <div className='form-group'>
              <label>Productos</label>
              <Form.Control onChange={(e) => dispatch(changeProductoSelect(e.target.value, usuarioFullData, cursos, sections))} as="select" disabled={loading}>
                <FillOption data={productos} defaultOptionText={'Seleccione un producto'} type="producto" />
              </Form.Control>
            </div>
          </Col>
          <Col md={2}>
            <div className='form-group'>
              <label>Partner</label>
              <Form.Control onChange={(e) => dispatch(changePartnerSelect(e.target.value))} as="select" disabled={loading}>
                <FillOption  data={selectPartner} defaultOptionText={'Todos los partners'} type="parter" />
              </Form.Control>
            </div>
          </Col>
          <Col md={1}>
            <div className='form-group'>
              <label>Idiomas</label>
              <Form.Control onChange={(e) => dispatch(changeIdiomaSelect(e.target.value, productoCurrent, cursos, sections))} as="select" disabled={loading}>
                <FillOption data={idiomas} defaultOptionText={'Todos los idiomas'} type="idioma" />
              </Form.Control>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <div className='form-group'>
              <label>Cursos</label>
              <Form.Control onChange={(e) => dispatch(changeCursoSelect(e.target.value, cursos, sections, cursosIDWithProducto))} as="select" disabled={loading}>
                <FillOption data={cursosByProducto} defaultOptionText={'Todos los cursos'} type="curso" />
              </Form.Control>
            </div>
          </Col>
          <Col md={3}>
            <div className='form-group'>
              <label>Secciones</label>
              <Form.Control onChange={(e) => dispatch(changeSectionSelect(e.target.value))} as="select" disabled={loading}>
                <FillOption data={seccionesByProducto} defaultOptionText={'Todas las secciones'} type="seccione" />
              </Form.Control>
            </div>
          </Col>
          <Col md={2}>
            <div className='form-group'>
              <label>Notas</label>
              <Form.Control onChange={(e) => dispatch(changeNotasSelect(e.target.value))} as="select" disabled={loading}>
                <option value="0">Operador</option>
                <option value="mayor">{"Mayor que (>)"}</option>
                <option value="menor">{"Menor que (<)"}</option>
                <option value="igual">{"Igual a (=)"}</option>
              </Form.Control>
              <Form.Control onChange={(e) => dispatch(changeValorSelect(e.target.value))} as="select" disabled={loading}>
                <option value="0">Nota</option>
                <option value="0">0</option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="40">40</option>
                <option value="50">50</option>
                <option value="60">60</option>
                <option value="70">70</option>
                <option value="80">80</option>
                <option value="90">90</option>
                <option value="100">100</option>
              </Form.Control>
            </div>
          </Col>
          <Col md={2}>
            <div className='form-group'>
              <label>Alumnos sin Actividad</label>
              <Form.Check checked={withoutActivity} onChange={(e) => dispatch(changeWithoutActivityCheck(!withoutActivity))} type="checkbox" label="No Mostrar" disabled={loading}/>
            </div>
          </Col>
        </Row>
        <Row>
          
          <Col md={{span:3,offset:9}}>
            <Button style={{margin: '0 1px'}} onClick={generatePDF} type='button' variant='danger' disabled={dataTableCertificado.length > 0 ? false : true }>PDF</Button>
            <Button style={{margin: '0 1px'}} onClick={generateExcel} type='button' variant='success' disabled={dataTableCertificado.length > 0 ? false : true }>EXCEL</Button>
            
            <Button style={{margin: '0 1px'}} onClick={consultarHandler} type='button' variant='primary' disabled={loading}>Consultar</Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}

const mapStateToProps = state => {
  return ({
    instancia: state.instancia,
    instancias: state.instancias,
    instanciaCurrent: state.instanciaCurrent,
    productos: state.productos,
    compras: state.compras,
    users: state.users,
    productoCurrent: state.productoCurrent,
    partners: state.partners,
    partnerData: state.partnerData,
    partnerSelect: state.partnerSelect,
    partnersClientes: state.partnersClientes,
    idiomas: state.idiomas,
    idiomaCurrent: state.idiomaCurrent,
    cursos: state.cursos,
    cursosByProducto: state.cursosByProducto,
    cursosIDWithProducto: state.cursosIDWithProducto,
    cursoCurrent: state.cursoCurrent,
    sections: state.sections,
    secciones: state.secciones,
    seccionesByProducto: state.seccionesByProducto,
    sectionCurrent: state.sectionCurrent,
    sectionStartDate: state.sectionStartDate,
    sectionEndDate: state.sectionEndDate,
    usuarioFullData: state.usuarioFullData,
    withoutActivity: state.withoutActivity,
    notas: state.notas,
    valor: state.valor,
    dataEnrolment: state.dataEnrolment,
    dataGrade: state.dataGrade,
    loading: state.loading,
    error: state.error,
    datatable: state.datatable,
    dataTableCertificado: state.dataTableCertificado,
    headerTable: state.headerTable,
  })
}

export default connect(mapStateToProps)(MoodleFiltros)