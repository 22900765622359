import { createStore, applyMiddleware, compose  } from 'redux';
import thunk from "redux-thunk";

import {  
    FETCH_INSTANCIA_BEGIN,
    FETCH_INSTANCIA_SUCCESS,
    FETCH_INSTANCIA_FAILURE,
    CHANGE_INSTANCIA_SELECT,
  } from '../src/redux/instanciaActions'

import {
    FETCH_PRODUCTO_BEGIN,
    FETCH_PRODUCTO_SUCCESS,
    CHANGE_PRODUCTO_SELECT,
} from '../src/redux/productoActions'

import {
    FETCH_CURSO_BEGIN,
    FETCH_CURSO_SUCCESS,
    FIll_CURSO_SELECT,
    CHANGE_CURSO_SELECT,
} from '../src/redux/cursoActions'

import {
    FETCH_SECTION_BEGIN,
    FETCH_SECTION_SUCCESS,
    FIll_SECTION_SELECT,
    CLEAR_SECTION_SELECT,
    CHANGE_SECTION_SELECT,
    CHANGE_SECTION_STARTDATE,
    CHANGE_SECTION_ENDDATE,
} from '../src/redux/sectionActions'

import {
    FETCH_PARTNER_BEGIN,
    FETCH_PARTNER_SUCCESS,
    FILL_SELECT_PARTNER,
    CHANGE_PARTNER_SELECT,
    FILL_PARTNERS_CLIENTES,
} from '../src/redux/partnerActions'

import {
    FETCH_USUARIOWITHPARTNER_BEGIN,
    FETCH_USUARIOWITHPARTNER_SUCCESS,
    USUARIOWITHPRODUCTCURRENT
} from '../src/redux/usuarioActions'

import {
    FETCH_IDIOMA_BEGIN,
    FETCH_IDIOMA_SUCCESS,
    CHANGE_IDIOMA_SELECT,
    DEFAULT_IDIMOA_SELECT,
} from '../src/redux/idiomaActions'

import {
    CHANGE_NOTAS_SELECT,
    CHANGE_VALOR_SELECT,
    CHANGE_WITHOUTACTIVITY_SELECT
} from '../src/redux/notasActions'

import {
    ENROLMENT_DATA_START,
    ENROLMENT_DATA,
    ENROLMENT_DATA_END
} from '../src/redux/enrolmentAction'

import {
    CONSULTAR_REPORTE_CALIFICACIONES_START,
    CONSULTAR_REPORTE_CALIFICACIONES_END,
    CONSULTAR_REPORTE_CALIFICACIONES_FILLDATA,
} from '../src/redux/consultarRCAction.js'

import {
    UPDATEHEADERTABLE,
} from '../src/redux/tableActions'


const reducer = (state, action) => {

    if(action.type === 'SELECT_CLIENT'){
        // console.log(state.cliente.concat(action.cliente))
        return {
            ...state,
            cliente: state.cliente.concat(action.cliente)
        }
    }
    if(action.type === 'LIST_CLIENT'){
        // console.log(state.listClientes.concat(action.listClientes))
        return {
            ...state,
            listClientes: state.listClientes.concat(action.listClientes)
        }
    }
    if(action.type === 'RE_RENDER'){
        return {
            ...state,
            rerender: state.rerender.concat(action.rerender)
        }
    }
    if(action.type === 'TOKEN'){
        // console.log(state.cliente.concat(action.cliente))
        return {
            ...state,
            token: state.token.concat(action.token)
        }
    }
    if(action.type === 'OPEN_TEMPLATE'){
        // console.log(state.cliente.concat(action.cliente))
        return {
            ...state,
            openTextEditor: state.openTextEditor.concat(action.openTextEditor)
        }
    }
    if(action.type === 'TEMPLATE_SELECTED'){
        // console.log(state.cliente.concat(action.cliente))
        return {
            ...state,
            template: state.template.concat(action.template)
        }
    }

    if(action.type === 'RERENDER_CERTIFICADOS'){
        // console.log(state.cliente.concat(action.cliente))
        return {
            ...state,
            rerenderCertificados: state.rerenderCertificados.concat(action.rerenderCertificados)
        }
    }

    if (action.type === FETCH_INSTANCIA_BEGIN) {
        return {
            ...state,
            loading: true,
            error: null
        };
    }
    if (action.type === FETCH_INSTANCIA_SUCCESS) {
        return {
            ...state,
            loading: false,
            instancias: action.payload.instancias
        };
    }
    if (action.type === FETCH_INSTANCIA_FAILURE) {
        return {
            ...state,
            loading: false,
            error: action.payload.error,
            instancias: [],
        };
    }

    if (action.type === CHANGE_INSTANCIA_SELECT) {
        return {
            ...state,
            loading: true,
            instanciaCurrent: action.payload.instancia
        }
    }

    if (action.type === FETCH_PRODUCTO_BEGIN) {
        return {
            ...state,
            loading: true,
            error: null
        };
    }

    if (action.type === FETCH_PRODUCTO_SUCCESS) {
        return {
            ...state,
            loading: false,
            productos: action.payload.productos,
            compras: action.payload.arrCompras,
            users: action.payload.arrUser,
        };
    }
    if (action.type === CHANGE_PRODUCTO_SELECT) {
        return {
            ...state,
            loading: false,
            datatable: action.payload.datatable,
            idiomas: action.payload.idiomas,
            idiomaCurrent: action.payload.idiomaCurrent,
            secciones: action.payload.secciones,
            productoCurrent: action.payload.productoCurrent
        };
    }
    
    if (action.type === FETCH_CURSO_BEGIN) {
        return {
            ...state,
            loading: true,
            error: null
        };
    }

    if (action.type === FETCH_CURSO_SUCCESS) {
        return {
            ...state,
            cursos: action.payload.cursos
        }
    }

    if (action.type === FIll_CURSO_SELECT) {
        return {
            ...state,
            cursosByProducto: action.payload.cursosByProducto,
            datatable: action.payload.cursosByProducto,
            cursosIDWithProducto: action.payload.cursosIDWithProducto,
        }
    }
    
    if (action.type === FETCH_SECTION_BEGIN) {
        return {
            ...state,
            loading: true,
            error: null
        };
    }

    if (action.type === FETCH_SECTION_SUCCESS) {
        return {
            ...state,
            sections: action.payload.sections
        };
    }
    
    if (action.type === FETCH_PARTNER_BEGIN) {
        return {
            ...state,
            loading: true,
            error: null
        };
    }

    if (action.type === FETCH_PARTNER_SUCCESS) {
        return {
            ...state,
            loading: false,
            partners: action.payload.partners
        };
    }
    
    if (action.type === FETCH_USUARIOWITHPARTNER_BEGIN) {
        return {
            ...state,
            loading: true,
            error: null
        };
    }

    if (action.type === FETCH_USUARIOWITHPARTNER_SUCCESS) {
        return {
            ...state,
            loading: false,
            usuarioFullData: action.payload.usuarioFullData
        };
    }

    if (action.type === USUARIOWITHPRODUCTCURRENT) {
        return {
            ...state,
            usuarioFullDataWithProductCurrent: action.payload.usuarioFullDataWithProductCurrent
        }
    }

    if (action.type === FETCH_IDIOMA_BEGIN) {
        return {
            ...state,
            loading: true,
            error: null
        };
    }

    if (action.type === FETCH_IDIOMA_SUCCESS) {
        return {
            ...state,
            loading: false,
            idiomas: action.payload.idiomas,
        };
    }

    if (action.type === DEFAULT_IDIMOA_SELECT) {
        return {
            ...state,
            idiomas: action.payload.idiomas,
        };
    }

    if (action.type === CLEAR_SECTION_SELECT) {
        return {
            ...state,
            secciones: [],
        }
    }

    if (action.type === FIll_SECTION_SELECT) {
        return {
            ...state,
            secciones: action.payload.secciones,
            seccionesByProducto: action.payload.seccionesByProducto,
        }
    }

    if (action.type === CHANGE_IDIOMA_SELECT) {
        return {
            ...state,
            idiomaCurrent: action.payload.idiomaCurrent,
            cursoCurrent: 0,
            sectionCurrent: 0
        }
    }

    if (action.type === CHANGE_CURSO_SELECT) {
        return {
            ...state,
            cursoCurrent: action.payload.cursoCurrent
        }
    }

    if (action.type === CHANGE_SECTION_SELECT) {
     return {
         ...state,
         sectionCurrent: action.payload.sectionCurrent
     }   
    }

    if (action.type === CHANGE_SECTION_STARTDATE) {
        return {
            ...state,
            sectionStartDate: action.payload.sectionStartDate
        }
    }
    
    if (action.type === CHANGE_SECTION_ENDDATE) {
        return {
            ...state,
            sectionEndDate: action.payload.sectionEndDate
        }
    }

    if (action.type === CHANGE_NOTAS_SELECT) {
        return {
            ...state,
            notas: action.payload.notas
        }
    }
    
    if (action.type === CHANGE_VALOR_SELECT) {
        return {
            ...state,
            valor: action.payload.valor
        }
    }

    if (action.type === CHANGE_WITHOUTACTIVITY_SELECT) {
        return {
            ...state,
            withoutActivity: action.payload.withoutActivity
        }
    }

    if (action.type === ENROLMENT_DATA) {
        return {
            ...state,
            dataEnrolment: action.payload.dataEnrolment,
            dataGrade: action.payload.dataGrade,
        }
    }
    
    if (action.type === ENROLMENT_DATA_START) {
        return {
            ...state,
            loading: action.payload.loading
        }
    }
    
    if (action.type === ENROLMENT_DATA_END) {
        return {
            ...state,
            loading: action.payload.loading
        }
    }

    if (action.type === CONSULTAR_REPORTE_CALIFICACIONES_START) {
        return {
            ...state,
            loading: action.payload.loading,
            dataTableCertificado: []
        }
    }
    
    if (action.type === CONSULTAR_REPORTE_CALIFICACIONES_END) {
        return {
            ...state,
            loading: action.payload.loading,
            dataTableCertificado: action.payload.dataTableCertificado
        }
    }

    if (action.type === FILL_SELECT_PARTNER) {
        return {
            ...state,
            partnerData: action.payload.data
        }
    }
    
    if (action.type === CHANGE_PARTNER_SELECT) {
        return {
            ...state,
            partnerSelect: action.payload.value
        }
    }

    if (action.type === FILL_PARTNERS_CLIENTES) {
        return {
            ...state,
            partnersClientes: action.payload.data
        }
    }

    if (action.type === UPDATEHEADERTABLE) {
        return {
            ...state,
            headerTable: action.payload.header
        }
    }


    return state;

};


const composeEnhancers = (null || compose);
//const composeEnhancers = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : (null || compose);
export default createStore(
    reducer, { 
        cliente: [], 
        listClientes : [], 
        rerender: [false], 
        token: [], 
        openTextEditor: [false], 
        template : [], 
        rerenderCertificados : [],
        loading: false,
        error: null, 
        instancias: [],
        instanciaCurrent: 0,
        productos: [],
        compras: [],
        users: [],
        productoCurrent: 0,
        cursos: [],
        cursosByProducto: [],
        cursosIDWithProducto: [],
        cursoCurrent: 0,
        sections: [],
        secciones: [],
        sectionCurrent: 0,
        sectionStartDate: '',
        sectionEndDate: '',
        seccionesByProducto: [],
        partners: [],
        partnerData: [],
        partnerSelect: 0,
        partnersClientes: [],
        idiomas: [],
        idiomaCurrent: 0,
        usuarioFullData: [],
        usuarioFullDataWithProductCurrent: [],
        notas: '0',
        valor: '0',
        dataEnrolment: [],
        dataGrade: [],
        withoutActivity: false,
        datatable: [],
        dataTableCertificado: [],
        headerTable: [],
    },
    composeEnhancers(applyMiddleware(thunk))
);