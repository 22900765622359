import React from 'react'
import MoodleFiltros from './MoodleFiltros'
import MoodleTable from './MoodleTable'

export default function Moodle() {
  return (
    <div className='row'>
      <div className='col-md-12'>
        <MoodleFiltros />
      </div>
      <div className='col-md-12'>
        <MoodleTable />
      </div>
    </div>
  )
}
