import { TOKEN_BARCA, TOKEN_CONMEBOL, TOKEN_OPENCAMPUS, URL_BARCA, URL_CONMEBOL, URL_OPENCAMPUS, URL_FEHGRA, TOKEN_FEHGRA } from '../const'
import { wsfunction } from '../helper'

export const FETCH_SECTION_BEGIN   = 'FETCH_SECTION_BEGIN';
export const FETCH_SECTION_SUCCESS = 'FETCH_SECTION_SUCCESS';
export const FIll_SECTION_SELECT = 'FIll_SECTION_SELECT';
export const CLEAR_SECTION_SELECT = 'CLEAR_SECTION_SELECT';
export const CHANGE_SECTION_SELECT = 'CHANGE_SECTION_SELECT';
export const CHANGE_SECTION_STARTDATE = 'CHANGE_SECTION_STARTDATE';
export const CHANGE_SECTION_ENDDATE = 'CHANGE_SECTION_ENDDATE';


export const fetchSectionBegin = () => ({
  type: FETCH_SECTION_BEGIN
});

export const fetchSectionSuccess = sections => ({
  type: FETCH_SECTION_SUCCESS,
  payload: { sections }
});

export const fetchSection = (idcliente, cursos) => {
  console.log('fetchSection')
  return (dispatch) => {
    dispatch(fetchSectionBegin())
    let URL = null;
    let TOKEN = null;

    if (idcliente == 101) {
      URL = URL_BARCA
      TOKEN = TOKEN_BARCA
    }
    if (idcliente == 110) {
      URL = URL_CONMEBOL
      TOKEN = TOKEN_CONMEBOL
    }
    if (idcliente == 111) {
      URL = URL_FEHGRA
      TOKEN = TOKEN_FEHGRA
    }
    if (idcliente == 119) {
      URL = URL_OPENCAMPUS
      TOKEN = TOKEN_OPENCAMPUS
    }
    const requestOptions = {
      method: 'POST',
    };

    fetch(`${URL}?wstoken=${TOKEN}&wsfunction=${wsfunction.core_course_get_courses}&moodlewsrestformat=json`, requestOptions)
        .then( response => response.json())
        .then( response => {
  
          console.log('Section response')
          console.log(response)

          const arrSectionAll = response;
         
          const arrSectionWithCourse = arrSectionAll.filter( section => {
            let addItem = false;
            cursos.forEach( curso => {
              if (curso.id === section.categoryid) {
                addItem = true;
              }
            });
            if (addItem) {
              return section
            }
          });
          
          dispatch(fetchSectionSuccess(arrSectionWithCourse))         
      });

  }

}

export const fillSectionSelect = (sections, arrCursosID, arrData) => dispatch => {

  const newArrMoodle = sections.filter( item => {
    let add = false;
    arrCursosID.forEach( moodleID => {
      if (item.categoryid === moodleID) {
        add = true;
        arrData.forEach( element => {
          if (element.curso_id == item.categoryid) {
            if (element.secciones === undefined) {
              element.secciones = [item]
            } else {
              element.secciones.push(item)
            }
          }
        });
      }
    })
    if (add) {
      return item;
    }
  });


  dispatch({
    type: CLEAR_SECTION_SELECT
  })
 
  const secciones = [];
  const seccionesByProducto = [];
  newArrMoodle.forEach( moodle => {
    
    secciones.push(moodle.id) // *** Llenar secciones de la lista por id
    seccionesByProducto.push({
      id: moodle.id,
      shortname:moodle.shortname
    })
  });


  dispatch({
    type: FIll_SECTION_SELECT,
    payload: {
      secciones,
      seccionesByProducto
    }
  })

}

export const changeSectionSelect = (section) => dispatch => {
 
  dispatch({
    type: CHANGE_SECTION_SELECT,
    payload: { sectionCurrent: section}
  })

}

export const changeSectionStartDate = (startDate) => dispatch => {
  dispatch({
    type: CHANGE_SECTION_STARTDATE,
    payload: { sectionStartDate: startDate}    
  })
}

export const changeSectionEndDate = (endDate) => dispatch => {
  dispatch({
    type: CHANGE_SECTION_ENDDATE,
    payload: { sectionEndDate: endDate}    
  })
}
