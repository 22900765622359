import React, { Component, Fragment } from 'react';
import { Animated } from "react-animated-css";
// import { Link } from 'react-router-dom';
import Loader from '../Global/Loader';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';

import AddSeccionLote from '../Enrolment/AddSeccionLote';
import AddSeccionManual from '../Enrolment/AddSeccionManual';



class ListSecciones extends Component {
    constructor(props) {
        super(props)
        this.state = {
            seccionesLoading: null,
            secciones: [],
            overlayShowSeccion: false,
            overlayShowSeccionManual: false,
            ShowHideModalEditarSeccion: false,
        }
        this.fetchData = this.fetchData.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.overlayShowHideSeccion = this.overlayShowHideSeccion.bind(this);
        this.overlayShowHideSeccionManual = this.overlayShowHideSeccionManual.bind(this);
        this.modalEditarSeccion = this.modalEditarSeccion.bind(this);
        this.handleEditarSeccion = this.handleEditarSeccion.bind(this);
    }

    componentDidUpdate(prevProps) {
        console.log(prevProps.cursoId.id !== this.props.cursoId.id)
        if (prevProps.cursoId.id !== this.props.cursoId.id) {
            if (typeof this.props.cursoId.id === "number") {
                this.fetchData();
            }
        }
    }

    fetchData() {
        var myHeaders = new Headers();
        myHeaders.append("Auth-Token", "zBKhfY9jXOUNVunScyIBltJJa3W1vg?HXsXPK5T1NCq1G!lXKN1ve9QOm25zIInZAQNgAK2VS7kTj7370NgqPg8w3DiQDb8AgtCCg6YNhU4wo45K!wEnOjPSnOtC8Rs");

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(window.$UrlOctavio + "/cursos/" + this.props.cursoId.id + "/secciones", requestOptions)
            .then(response => response.json())
            .then(result => {

                this.setState({
                    secciones: result,
                    seccionesLoading: false
                })
            })
            .catch(error => {
                console.log('error', error)
            })
    }

    handleClose() {
        this.props.handleFuncion()
        this.setState({
            seccionesLoading: null
        })
    }

    overlayShowHideSeccion() {
        this.setState(prevState => ({
            overlayShowSeccion: !prevState.overlayShowSeccion
        }));
    }

    overlayShowHideSeccionManual() {
        this.setState(prevState => ({
            overlayShowSeccionManual: !prevState.overlayShowSeccionManual
        }));
    }

    handleEditarSeccion(nombre, inicio, fin) {
        this.setState({
            seccionEditableNombre: nombre,
            seccionEditableInicio: inicio,
            seccionEditableFin: fin
        })
        this.setState(prevState => ({
            ShowHideModalEditarSeccion: !prevState.ShowHideModalEditarSeccion,
        }));
    }

    modalEditarSeccion() {
        if (this.state.ShowHideModalEditarSeccion === true) {
            return (
                <div className="my-modal zindextop">
                    <div className="my-modal-content">
                        <div className="my-modal-header">
                            <div className="row d-flex justify-content-between align-items-center">
                                <h6>Editar seccion</h6>
                                <button onClick={this.handleEditarSeccion} className="btn btn-close"><i className="fa fa-times"></i></button>
                            </div>
                        </div>
                        <div className="my-modal-body text-left">
                            <div className="form-group">
                                <label htmlFor="seccion_nombre">Nombre</label>
                                <input id="seccion_nombre" type="text" className="form-control" name="seccion_nombre" placeholder={this.state.seccionEditableNombre} required></input>
                                <div className="invalid-feedback">Ingresa el nombre de la sección</div>
                                <div className="valid-feedback">Excelente!</div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="seccion_fecha_inicio">Fecha de inicio</label>
                                <input id="seccion_fecha_inicio" type="date" className="form-control" name="seccion_fecha_inicio" placeholder={this.state.seccionEditableInicio} required></input>
                                <div className="invalid-feedback">Ingresa la fecha de la sección</div>
                                <div className="valid-feedback">Excelente!</div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="seccion_fecha_fin">Fecha de fin</label>
                                <input id="seccion_fecha_fin" type="date" className="form-control" name="seccion_fecha_fin" placeholder={this.state.seccionEditableFin} required></input>
                                <div className="invalid-feedback">Ingresa la fecha de la sección</div>
                                <div className="valid-feedback">Excelente!</div>
                            </div>
                        </div>
                        <div className="my-modal-footer d-flex justify-content-center align-items-stretch">
                            <button className="btn btn-secondary mr-2" onClick={this.handleEditarSeccion}>Cancelar</button>
                            <button className="btn btn-primary">Guardar</button>
                        </div>
                    </div>
                </div>
            )
        } else {
            return false;
        }
    }

    render() {

        const columns = [
            {
                dataField: 'idCanvas',
                sort: true,
                sortCaret: (order, column) => {
                    if (!order) return (<i className="ml-2 fas fa-sort" key={column.idCanvas}></i>);
                    else if (order === 'asc') return (<i className="ml-2 fas fa-sort-up" key={column.idCanvas}></i>);
                    else if (order === 'desc') return (<i className="ml-2 fas fa-sort-down" key={column.idCanvas}></i>);
                    return null;
                },
                text: 'SIS Id',
                align: 'center',
                headerClasses: 'border-0',
                uniqueData: "idCanvas",
                headerStyle: () => {
                    return { width: "10%" };
                },
                formatter: (cellContent, row) => {
                    return (
                        <span className="badge badge-primary">{row.idCanvas}</span>
                    );
                }
            },
            {
                dataField: 'nombre',
                sort: true,
                sortCaret: (order, column) => {
                    if (!order) return (<i className="ml-2 fas fa-sort"></i>);
                    else if (order === 'asc') return (<i className="ml-2 fas fa-sort-up"></i>);
                    else if (order === 'desc') return (<i className="ml-2 fas fa-sort-down"></i>);
                    return null;
                },
                text: 'Nombre',
                headerClasses: 'border-0',
                headerStyle: () => {
                    return { width: "40%" };
                },
                uniqueData: "nombre",
                style: {
                    width: "200px"
                }
            },
            {
                dataField: 'fechaInicio',
                sort: true,
                sortCaret: (order, column) => {
                    if (!order) return (<i className="ml-2 fas fa-sort"></i>);
                    else if (order === 'asc') return (<i className="ml-2 fas fa-sort-up"></i>);
                    else if (order === 'desc') return (<i className="ml-2 fas fa-sort-down"></i>);
                    return null;
                },
                text: 'Fecha de Inicio',
                headerClasses: 'border-0',
                uniqueData: "fechaInicio",
                style: {
                    width: "200px"
                }
            },
            {
                dataField: 'fechaFin',
                sort: true,
                sortCaret: (order, column) => {
                    if (!order) return (<i className="ml-2 fas fa-sort"></i>);
                    else if (order === 'asc') return (<i className="ml-2 fas fa-sort-up"></i>);
                    else if (order === 'desc') return (<i className="ml-2 fas fa-sort-down"></i>);
                    return null;
                },
                text: 'Fecha de finalización',
                headerClasses: 'border-0',
                uniqueData: "fechaFin",
                style: {
                    width: "200px"
                }
            },
            {
                dataField: 'acciones',
                text: 'Acciones',
                headerClasses: 'border-0',
                uniqueData: "acciones",
                formatter: (row) => {
                    return (
                        <button onClick={() => this.handleEditarSeccion()} className="btn btn-secondary mr-3">Editar</button>
                    );
                }
        
            }
        ];
        const rowClasses = 'custom-row-class';
        // const { SearchBar } = Search;
        
        
        const MySearch = (props) => {
            let input;
            const handleClick = () => {
                props.onSearch(input.value);
            };
            return (
                <input
                    id="searchCustom"
                    className="form-control input-search custom-big-search"
                    ref={n => input = n}
                    type="text"
                    placeholder="Buscar"
                    onChange={handleClick}
                />
            );
        };

        if (this.props.handle) {
            return (
                <div>
                    {this.modalEditarSeccion()}
                    <AddSeccionLote handleFuncion={this.overlayShowHideSeccion} show={this.state.overlayShowSeccion}></AddSeccionLote>
                    <AddSeccionManual cursoNombre={this.props.cursoNombre} cursoId={this.props.cursoId} handleFuncion={this.overlayShowHideSeccionManual} secciones={this.state.secciones} show={this.state.overlayShowSeccionManual}></AddSeccionManual>

                    <div className="my-modal">

                        <div className="my-modal-content max-w-90">
                            <div className="my-modal-header">
                                <div className="row d-flex justify-content-between align-items-center">
                                    <h6>Secciones</h6>
                                    <button onClick={() => this.handleClose()} className="btn btn-close"><i className="fa fa-times"></i></button>
                                </div>
                            </div>
                            <div className="my-modal-body text-left min-h-50 pt-0">
                                {this.state.seccionesLoading === null ? <div className="pt-5"><Loader /></div> :
                                    <Animated animationIn="slideInUp" animationOut="fadeOut" animationInDuration={380} isVisible={true}>
                                        <ToolkitProvider
                                            keyField='id'
                                            data={this.state.secciones}
                                            columns={columns}
                                            search={<MySearch />}
                                            exportCSV
                                        >
                                            {
                                                props => (
                                                    <Fragment>
                                                        <div className="search-group d-flex align-items-center justify-content-start mb-3 pt-3 pb-3 sticky-top bg-white" key={4898794654654}>
                                                            <label htmlFor="searchCustom" className="customlabelSearch">Buscar secciones</label>
                                                            <MySearch
                                                                {...props.searchProps}
                                                            />
                                                        </div>

                                                        <BootstrapTable
                                                            striped
                                                            hover
                                                            bordered={false}
                                                            headerClasses="header-class"
                                                            pagination={paginationFactory()}
                                                            {...props.baseProps}
                                                            rowClasses={rowClasses}
                                                            paginationSize={50}
                                                        />

                                                    </Fragment>
                                                )
                                            }
                                        </ToolkitProvider>
                                    </Animated>
                                }
                            </div>
                            <div className="my-modal-footer d-flex justify-content-center align-items-stretch">
                                <button className="btn btn-secondary mr-2" onClick={() => this.overlayShowHideSeccion()}>Añadir secciónes por lotes</button>
                                <button className="btn btn-primary" onClick={() => this.overlayShowHideSeccionManual()}>Añadir sección manualmente</button>
                            </div>
                        </div>
                    </div>
                </div>
            )

        } else {
            return false
        }
    }
}

export default ListSecciones;
