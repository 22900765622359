import React from 'react'
import { INSTANCIA_ACTIVE } from '../../const'
import { getAllParter } from '../../data'

export default function FillOption({ data, defaultOptionText, type }) {

  let cmp = [<option key={0} value={0}>{ defaultOptionText }</option>]

  if (data.length > 0) {
    data.forEach((element) => {

      // *** Var
      let add = false;

      // *** Instancia Select Start ***
      if (type === 'instancia') {
        if (element.id > 100) {
          
          INSTANCIA_ACTIVE.forEach( item => {
            if (element.id === item) {
              add = true;
            }
          })
          if (add) {
            cmp.push(<option key={element.nombreHubspot} value={element.id}>{element.nombreHubspot}</option>)
          }
        }
      }
      // *** Instancia Select End ***
      
      // *** Producto Select Start ***
      if (type === 'producto') {
        add = true;
        if (add) {
          cmp.push(<option key={element.id} value={element.id}>{element.nombre}</option>)
        }
      }
      // *** Producto Select End ***
     
      // *** Partner Select Start ***
      if (type === 'parter') {
        add = true;
        if (add) {
          cmp.push(<option key={element.id} value={element.id}>{element.nombre}</option>)
        }
      }
      // *** Partner Select End ***

      // *** Idioma Select Start ***
      if (type === 'idioma') {
        cmp.push(<option key={element.name} value={element.value}>{element.name}</option>)
      }
      // *** Idioma Select End ***

      // *** Producto Select Start ***
      if (type === 'curso') {
        cmp.push(<option key={element.curso_id} value={element.curso_id}>{element.curso}</option>)
      }
      // *** Producto Select End ***

      // *** Seccione Select Start ***
      if (type === 'seccione') {
        cmp.push(<option key={element.id} value={element.id}>{element.shortname}</option>)
      }
      // *** Seccione Select End ***
    })
  }

  return cmp;
}
