export const wsfunction = {
  core_course_get_categories: 'core_course_get_categories',
  core_course_get_courses: 'core_course_get_courses',
  core_enrol_get_enrolled_users: 'core_enrol_get_enrolled_users',
  gradereport_user_get_grade_items: 'gradereport_user_get_grade_items',
  core_user_get_users_by_field: 'core_user_get_users_by_field',
  gradereport_overview_get_course_grades: 'gradereport_overview_get_course_grades',
}

export const asyncForEach = async(array, callback) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}