import { OCTAVIO_URL, OCTAVIO_TOKEN } from '../const'
import { fillCursoSelect } from './cursoActions'
import { fillSectionSelect } from './sectionActions'

export const FETCH_IDIOMA_BEGIN   = 'FETCH_IDIOMA_BEGIN';
export const FETCH_IDIOMA_SUCCESS = 'FETCH_IDIOMA_SUCCESS';
export const CHANGE_IDIOMA_SELECT = 'CHANGE_IDIOMA_SELECT';
export const DEFAULT_IDIMOA_SELECT = 'DEFAULT_IDIMOA_SELEC';


export const defaultIdiomaSelect = () =>  dispatch => {

  const idiomas = [
    {name: 'ES', value: 'ES'},
    {name: 'EN', value: 'EN'},
    {name: 'PT', value: 'PT'},
    {name: 'AR', value: 'AR'},
    {name: 'RU', value: 'RU'},
  ];

  dispatch({
    type: 'DEFAULT_IDIMOA_SELEC',
    payload: {
      idiomas,
    }
  })

}

export const fetchIdiomaBegin = () => ({
  type: FETCH_IDIOMA_BEGIN
});

export const fetchIdiomaSuccess = (idiomas) => dispatch => {

  dispatch({
    type: FETCH_IDIOMA_SUCCESS,
    payload: { 
      idiomas, 
    }
  });
  
};

export const fetchIdioma = (producto, cursos, sections) => {
  console.log('fetchIdioma')
  console.log('cursos: ', cursos)
  return async(dispatch) => {
    dispatch(fetchIdiomaBegin())
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Auth-Token", OCTAVIO_TOKEN);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(OCTAVIO_URL + "/productos/"+producto, requestOptions)
      .then(response => response.text())
      .then(result => {
        const data = JSON.parse(result)

        // *** Get getArrMoodle Start ***
        const optionSelectIdiomas = [];
        let getArrMoodle = [];
        let arrIdiomas = data.idiomas
        let arrMoodle = JSON.parse(data.idsMoodle);
        arrIdiomas.forEach((element, index) => {
          if (arrMoodle[element] !== undefined) {
            const value = arrMoodle[element].join();
            const arrMoodleID = arrMoodle[element];
            arrMoodleID.forEach( ID => {
              getArrMoodle.push({producto: producto, id: ID})
              console.log('getArrMoodle:', getArrMoodle)
            });
            optionSelectIdiomas.push({
              value,
              name: element.toUpperCase(),
            });
          } 
        });
        // *** Get getArrMoodle End ***
        dispatch(fetchIdiomaSuccess(optionSelectIdiomas))

        // *** Fill Select Curso And Get getArrCursosID Start ***
        const newArrMoodle = cursos.filter( curso => {
          let add = false;
          let producto = null
          getArrMoodle.forEach( moodleID => {
            if (curso.id ===  moodleID.id) {
              add = true;
              producto = moodleID.producto
            }
          })
          if (add) {
            curso['producto'] = producto 
            console.log('curso: ', curso)
            return curso;
          }
        });
        
        let arrCursosID = [];
        const arrData = []
        newArrMoodle.forEach( moodle => {
          console.log('moodle')
          console.log(moodle)
          arrCursosID.push(moodle.id);
          arrData.push(
            {
              producto: moodle.producto,
              curso:moodle.name,
              curso_id:moodle.id
            }
          ); 
        });
        // *** Fill Select Curso And Get getArrCursosID End ***
        dispatch(fillCursoSelect(arrData, arrCursosID))
        dispatch(fillSectionSelect(sections, arrCursosID, arrData))
      })
    
  }

}

export const changeIdiomaSelect = (idioma, productoCurrent, cursos, sections) => dispatch => {
  console.log('changeIdiomaSelect')
  
  if (productoCurrent == '0') {
    
  } else {
    dispatch({
      type: CHANGE_IDIOMA_SELECT,
      payload: {
        idiomaCurrent: idioma
      }
    })
  
    if (idioma == '0') {
      dispatch(fetchIdioma(productoCurrent, cursos, sections))
    } else {
      const getArrMoodle = idioma.split(',').map((item) => {
        return parseInt(item);
      });
  
      // *** Fill Select Curso And Get getArrCursosID Start ***
      const newArrMoodle = cursos.filter( curso => {
        let add = false;
        let producto = null
        getArrMoodle.forEach( moodleID => {
          if (curso.id === moodleID) {
            add = true;
            producto = productoCurrent
          }
        })
        if (add) {
          curso['producto'] = producto
          return curso;
        }
      });
      
      let arrCursosID = [];
      const arrData = []
      newArrMoodle.forEach( moodle => {
        console.log('moodle')
        console.log(moodle)
        arrCursosID.push(moodle.id);
        arrData.push(
          {
            producto: moodle.producto,
            curso:moodle.name,
            curso_id:moodle.id
          }
        ); 
      });
      // *** Fill Select Curso And Get getArrCursosID End ***
      dispatch(fillCursoSelect(arrData, arrCursosID))
      dispatch(fillSectionSelect(sections, arrCursosID, arrData))
  }

  }


}
