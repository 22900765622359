import { TOKEN_BARCA, TOKEN_CONMEBOL, URL_BARCA, URL_CONMEBOL, TOKEN_OPENCAMPUS, URL_OPENCAMPUS, URL_FEHGRA, TOKEN_FEHGRA } from '../const'
import { wsfunction } from '../helper'
import { fetchSection, fillSectionSelect } from './sectionActions'

export const FETCH_CURSO_BEGIN   = 'FETCH_CURSO_BEGIN';
export const FETCH_CURSO_SUCCESS = 'FETCH_CURSO_SUCCESS';
export const FIll_CURSO_SELECT = 'FIll_CURSO_SELECT';
export const CHANGE_CURSO_SELECT = 'CHANGE_CURSO_SELECT';

export const fetchCursoBegin = () => ({
  type: FETCH_CURSO_BEGIN
});

export const fetchCursoSuccess = (cliente, cursos) => dispatch => {

  dispatch({
    type: FETCH_CURSO_SUCCESS,
    payload: { cursos }
  })

  dispatch(fetchSection(cliente, cursos))

};

export const fetchCurso = (idcliente) => {
  console.log('fetchCurso')
  return (dispatch) => {
    dispatch(fetchCursoBegin())
    let URL = null;
    let TOKEN = null;

    if (idcliente == 101) {
      URL = URL_BARCA
      TOKEN = TOKEN_BARCA
    }
    if (idcliente == 110) {
      URL = URL_CONMEBOL
      TOKEN = TOKEN_CONMEBOL
    }
    if (idcliente == 111) {
      URL = URL_FEHGRA
      TOKEN = TOKEN_FEHGRA
    }
    if (idcliente == 119) {
      URL = URL_OPENCAMPUS
      TOKEN = TOKEN_OPENCAMPUS
    }
    const requestOptions = {
      method: 'POST',
    };

     fetch(`${URL}?wstoken=${TOKEN}&wsfunction=${wsfunction.core_course_get_categories}&criteria[0][key]=visible&criteria[0][value]=1&moodlewsrestformat=json`, requestOptions)
        .then( response => response.json())
        .then( response => {
          dispatch(fetchCursoSuccess(idcliente, response))
        });

  }

}

export const fillCursoSelect = (cursosByProducto, arrCursosID) => dispatch => {
  dispatch({
    type: FIll_CURSO_SELECT,
    payload: {  
      cursosByProducto: cursosByProducto, 
      datatable: cursosByProducto, 
      cursosIDWithProducto: arrCursosID
    }
  })

}

export const changeCursoSelect = (curso, cursos, sections, cursosIDWithProducto) => dispatch => {
  console.log('changeCursoSelect')
  dispatch({
    type: CHANGE_CURSO_SELECT,
    payload: {cursoCurrent: curso}
  });

  if (curso == '0') {
    console.log('is Curso === 0')
    const getArrMoodle = cursosIDWithProducto
    const newArrMoodle = cursos.filter( curso => {
      let add = false;
      getArrMoodle.forEach( moodleID => {
        if (curso.id === moodleID) {
          add = true;
        }
      })
      if (add) {
        return curso;
      }
    });
    let arrCursosID = [];
    const arrData = []
    newArrMoodle.forEach( moodle => {
      arrCursosID.push(moodle.id);
      arrData.push(
        {
          curso:moodle.name,
          curso_id:moodle.id
        }
      ); 
    });

    dispatch(fillSectionSelect(sections, arrCursosID, arrData))
  } else {
    console.log('is Curso !== 0')
    const getArrMoodle = [parseInt(curso)];
    const newArrMoodle = cursos.filter( curso => {
      let add = false;
      getArrMoodle.forEach( moodleID => {
        if (curso.id === moodleID) {
          add = true;
        }
      })
      if (add) {
        return curso;
      }
    });
    let arrCursosID = [];
    const arrData = []
    newArrMoodle.forEach( moodle => {
      arrCursosID.push(moodle.id);
      arrData.push(
        {
          curso:moodle.name,
          curso_id:moodle.id
        }
      ); 
    });
    dispatch(fillSectionSelect(sections, arrCursosID, arrData))    
  }

}
