import { OCTAVIO_URL, OCTAVIO_TOKEN } from '../const'
import { fetchUsuarioPartner } from './usuarioActions'

export const FETCH_PARTNER_BEGIN   = 'FETCH_PARTNER_BEGIN';
export const FETCH_PARTNER_SUCCESS = 'FETCH_PARTNER_SUCCESS';
export const FILL_SELECT_PARTNER = 'FILL_SELECT_PARTNER';
export const CHANGE_PARTNER_SELECT = 'CHANGE_PARTNER_SELECT';
export const FILL_PARTNERS_CLIENTES = 'FILL_PARTNERS_CLIENTES';

export const fetchPartnersClientes = (cliente) => dispatch => {

  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Auth-Token", OCTAVIO_TOKEN);
  myHeaders.append("cliente", cliente);
  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  fetch("https://octaviodev.capabiliaserver.com/api/v1/partners/clientes", requestOptions)
      .then(response => response.text())
      .then(result => {
        
        let data = JSON.parse(result)
        console.log('PC: ', data)
        
        dispatch({
          type: FILL_PARTNERS_CLIENTES,
          payload: {data}
        })

      })

}


export const changePartnerSelect = (value) => dispatch => {
  dispatch({
    type: CHANGE_PARTNER_SELECT,
    payload: {value}
  })
}

export const fetchPartnerBegin = () => ({
  type: FETCH_PARTNER_BEGIN
});

export const fetchPartnerSuccess = (partners, cliente) => dispatch => {

  dispatch({
    type: FETCH_PARTNER_SUCCESS,
    payload: { partners }
  })

  dispatch(fetchUsuarioPartner(partners, cliente));
};


export const fetchParter = (cliente, productos) => {
  console.log('fetchParter')
  return (dispatch) => {
    
    dispatch(fetchPartnerBegin())
    
    const myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("Auth-Token", OCTAVIO_TOKEN);
      myHeaders.append("cliente", cliente);
      const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      fetch(OCTAVIO_URL + "/compras?fullCompra=false&disablePagination=false", requestOptions)
      .then(response => response.text())
      .then(result => {
        
        let data = JSON.parse(result)
        // *** Filter Moodle ***
        const dataFilter = data.filter( compra => {

          let isCurrentProduct = false;

          const arrCompraProducto = JSON.parse(compra.productos);

          arrCompraProducto.forEach( compraProductoItem => {
            
            productos.forEach( product => {
              if (compraProductoItem.producto == product.id) {
                isCurrentProduct = true
              }
            });

          });


          if (compra.partner !== null && compra.partner !== '' && isCurrentProduct) {
            return compra;
          }
        })        
        dispatch(fetchPartnerSuccess(dataFilter, cliente));
      })
  }

}

export const fillSelectPartner = () => dispatch => {
  console.log('fillSelectPartner')
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Auth-Token", OCTAVIO_TOKEN);
  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };
  
  fetch("https://octaviodev.capabiliaserver.com/api/v1/partners", requestOptions)
      .then(response => response.text())
      .then(result => {
        
        let data = JSON.parse(result)
        console.log('PARTNERS: ', data)
        
        dispatch({
          type: FILL_SELECT_PARTNER,
          payload: {data}
        })

      })

}