import React from 'react'
import { Tabs, Tab } from 'react-bootstrap'
import Navigation from '../Navigation/Navigation';
import SecondaryNavigationEmpty from '../SecondaryNavigation/SecondaryNavigationEmpty';
import { ExcelRenderer, OutTable } from 'react-excel-renderer';
import { Animated } from "react-animated-css";
import Moodle from './Moodle'
import Canvas from './Canvas'

export default function Calificaciones() {
  return (
    <div>
      <Navigation />
      <SecondaryNavigationEmpty title="Calificaciones" />
      <div className='container mt-4'>
        <div className='row'>
          <div className='col-md-12'>
          <Tabs defaultActiveKey="moodle" id="uncontrolled-tab">
            <Tab eventKey="canvas" title="Canvas">
              <Canvas />
            </Tab>
            <Tab eventKey="moodle" title="Moodle">
              <Moodle />
            </Tab>
          </Tabs>
          </div>
        </div>
      </div>
    </div>
  )
}
