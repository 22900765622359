import React  from 'react'
import { Card, Row, Col, Table } from 'react-bootstrap'
import DataTable from 'react-data-table-component';
import { connect } from 'react-redux'

function MoodleTable({loading, sectionCurrent, headerTable, dataTableCertificado})  {

  const columns = [
    {
      name: 'STUDENT NAME',
      selector: 'fullNameStudent',
      sortable: true,
      wrap: true,
    },
    {
      name: 'STUDENT SISID',
      selector: 'idStudent',
      sortable: true,
    },
    {
      name: 'STUDENT EMAIL',
      selector: 'emailStudent',
      sortable: true,
      wrap: true,
    },
    {
      name: 'COURSE',
      selector: 'course',
      sortable: true,
      wrap: true,
    },
    {
      name: 'SECTION',
      selector: 'section',
      sortable: true,
      wrap: true,
    },
    {
      name: 'START DATE',
      selector: 'startDate',
      sortable: true,
    },
    {
      name: 'DIAGNOSTIC',
      selector: 'diagnostic',
      sortable: true,
    },
    {
      name: 'ACTIVITY',
      selector: 'activity1',
      sortable: true,
    },
    {
      name: 'ACTIVITY',
      selector: 'activity2',
      sortable: true,
    },
    {
      name: 'ACTIVITY',
      selector: 'activity3',
      sortable: true,
    },
    {
      name: 'ACTIVITY',
      selector: 'activity4',
      sortable: true,
    },
    {
      name: 'FINAL',
      selector: 'final',
      sortable: true,
    },
    {
      name: 'FINAL SCORE',
      selector: 'finalScore',
      sortable: true,
    },
    {
      name: 'PARTNER',
      selector: 'partner',
      sortable: true,
    },
    
  ];

  const ExpandedComponentNotes = ({data}) => {

    data.notes.sort((a,b) => {
      return a.id - b.id;
    })
    
    const cmpHT = data.notes.map( (element,index) => {  
      return (
        <th key={index}>{ element.nameItem }</th>
      )
    })

    const cmpBT = data.notes.map( (element,index) => {
      return (
        <td key={index}>{ element.grade }</td>
      )
    })

    return (
      <React.Fragment>
        <Table striped bordered hover>
          <thead>
            <tr>
              { cmpHT }
            </tr>
          </thead>
          <tbody>
            <tr>
              { cmpBT }
            </tr>
          </tbody>
        </Table>
      </React.Fragment>
    )
  }

  
  let cmpTable = null

  cmpTable = <DataTable
    columns={columns}
    data={dataTableCertificado}
    pagination
    progressPending={loading}
    expandableRows={true}
    expandableRowsComponent={<ExpandedComponentNotes />}
  />;

  return (
    <Card>
      <Card.Body>
        <Row>
          <Col md={12}>
            { cmpTable }
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}

const mapStateToProps = state => {
  return ({
    loading: state.loading,
    error: state.error,
    sectionCurrent: state.sectionCurrent,
    headerTable: state.headerTable,
    dataTableCertificado: state.dataTableCertificado,
  })
}

export default connect(mapStateToProps)(MoodleTable);
