import { updateHeaderTable } from './tableActions'

export const CONSULTAR_REPORTE_CALIFICACIONES_START = 'CONSULTAR_REPORTE_CALIFICACIONES_START'
export const CONSULTAR_REPORTE_CALIFICACIONES_END = 'CONSULTAR_REPORTE_CALIFICACIONES_END'
export const CONSULTAR_REPORTE_CALIFICACIONES_FILLDATA = 'CONSULTAR_REPORTE_CALIFICACIONES_FILLDATA'

export const consultarReporteCalificacionesStart = () => dispatch => {

  dispatch({
    type: 'CONSULTAR_REPORTE_CALIFICACIONES_START',
    payload: {loading: true}
  })

}

export const consultarReporteCalificacionesEnd = (dataTableCertificadoTemp) => dispatch => {

  dispatch({
    type: 'CONSULTAR_REPORTE_CALIFICACIONES_END',
    payload: {loading: false, dataTableCertificado: dataTableCertificadoTemp}
  })

}

export const consultarReporteCalificaciones = (dataEnrolment, dataGrade, datatable, hasFilterNote, valorFilter, operadorFilter, cursoCurrent, sectionCurrent, sectionStartDate, sectionEndDate, withoutActivity, partnerSelect, selectPartner, arrDataGradeFinalScore) => dispatch => {

  dispatch(consultarReporteCalificacionesStart());

  let dataTableCertificadoTemp = []

  // *** Build Table Start
  dataEnrolment.forEach( itemSeccionStudent => {
    console.log('itemSeccionStudent')
    console.log(itemSeccionStudent)
      let curso_current = '';
      let seccion_current = '';
      let seccionID = '';
      let cursoID = '';
      let fechaInicio = 0;
      let fechaInicioTimeStamp = null;
      let currentNote = 0;
      let finalNote = 0;
      let finalScore = 0;
      let diagnostic = 0;
      let activity1 = 0;
      let activity2 = 0;
      let activity3 = 0;
      let activity4 = 0;
      let autoevaluation1 = 0;
      let autoevaluation2 = 0;
      let autoevaluation3 = 0;
      let autoevaluation4 = 0;
      let email = '';

      datatable.forEach( cursoItem => {
        cursoItem.secciones.forEach( seccionItem => {
          if (seccionItem.id == itemSeccionStudent.seccion_id) {
            cursoID = cursoItem.curso_id;
            curso_current = cursoItem.curso;
            seccion_current = seccionItem.shortname;
            if (seccionItem.startdate != 0) {
              let formatStartDate =  new Date(seccionItem.startdate * 1000);
              fechaInicioTimeStamp = seccionItem.startdate;
              let datebase = new Date('2022-01-01')
              if (formatStartDate.getTime() < datebase.getTime()) {
                fechaInicio = `${formatStartDate.getDate()}/${formatStartDate.getMonth() + 1}/${formatStartDate.getFullYear()}`; 
              } else {
                fechaInicio = `${formatStartDate.getDate()}/${formatStartDate.getMonth() + 1}/${formatStartDate.getFullYear()}`; 
              }

            }    
            seccionID = seccionItem.id;

            console.log('seccionID:', seccionID)

          }
        })
      }); // *** End [datatable]

      // ***                ***
      // *** Fill DataTable ***
      // ***                ***

      itemSeccionStudent.students.forEach( itemStudent => {

        console.log('itemStudent')
        console.log(itemStudent)
        const allNote = {}
        let notes = [];
        let add = true;
        finalNote = 0;
        let partner = '';
        const activityArr = []
        const autoEvaluationArr = []
        email = itemStudent.email

        // *** Get Final Score Start
        for (const itemFinalScore of arrDataGradeFinalScore) {
          let isStudent = false;
          if (itemStudent.id == itemFinalScore.student) {
            isStudent = true
          }
          if (isStudent) {
            for (const gradeFS of itemFinalScore.grades) {
              console.log('gradeFS: ', gradeFS)
              if (seccionID == gradeFS.courseid) {
                
                finalScore = gradeFS.rawgrade != null ? gradeFS.grade : 0 
              }
            } 
          }
        }
        // *** Get Final Score End
    
        dataGrade.forEach( baseDataGrade => {

          if (baseDataGrade.seccion_id == itemSeccionStudent.seccion_id) {
            baseDataGrade.grades.usergrades.forEach( usergrades => {
              if (usergrades.userid == itemStudent.id) {
                
                const { gradeitems } = usergrades;
                
                finalNote = 0; 
                gradeitems.forEach( (element, index) => {
                  const { itemname, itemmodule, grademax, graderaw, id } = element;
                  let newItemName = itemname === null ? '' : itemname.toUpperCase();
                  //newItemName = newItemName.replace(/\s/g, '');

                  if (newItemName !== '') {
                    console.log('element')
                    console.log(element)

                    
                    let myNote = graderaw === null || graderaw === 0 ? '' : graderaw.toFixed(2);
                    allNote[index] = myNote
                    
                    notes.push({
                      id:id,
                      nameItem:newItemName.trim().toUpperCase(),
                      grade: myNote
                    })

                    if (
                      newItemName.trim().toUpperCase() == 'EXAMEN FINAL' ||
                      newItemName.trim().toUpperCase() == 'FINAL ASSESSMENT' ||
                      newItemName.trim().toUpperCase() == 'EXAME FINAL' ||
                      newItemName.trim().toUpperCase() == 'ФИНАЛЬНЫЙ ЭКЗАМЕН' ||
                      newItemName.trim().toUpperCase() == 'امتحان نهائي'
                      ) {
                      
                      let myGrade = graderaw === null ? 0 : graderaw.toFixed(2);
                      currentNote = myGrade;
                    }
                   
                    if (itemmodule == 'quiz' && !newItemName.trim().toUpperCase().includes('DIAGNÓSTICA')) {
                      
                      let myGradeAutoEva = graderaw === null ? 0 : graderaw.toFixed(2);
                      autoEvaluationArr.push(myGradeAutoEva)
                      if (autoEvaluationArr.length === 1) {
                        autoevaluation1 = myGradeAutoEva
                      }
                      if (autoEvaluationArr.length === 2) {
                        autoevaluation2 = myGradeAutoEva
                      }
                      if (autoEvaluationArr.length === 3) {
                        autoevaluation3 = myGradeAutoEva
                      }
                      if (autoEvaluationArr.length === 4) {
                        autoevaluation4 = myGradeAutoEva
                      }
                    }
                    
                    // *** Activity ***
                    if (
                      itemmodule == 'assign'
                    ) {
                      let myGradeAct = graderaw === null ? 0 : graderaw.toFixed(2);
                      activityArr.push(myGradeAct)
                      
                      console.log('activityArr.length: ', activityArr.length)
                      if (activityArr.length === 1) {
                        activity1 = myGradeAct
                      }
                      if (activityArr.length === 2) {
                        activity2 = myGradeAct
                      }
                      if (activityArr.length === 3) {
                        activity3 = myGradeAct
                      }
                      if (activityArr.length === 4) {
                        activity4 = myGradeAct
                      }
                      
                    }
                    // *** Diagnostic *** 
                    if (
                      newItemName.trim().toUpperCase() == 'DIAGNÓSTICA' ||
                      newItemName.trim().toUpperCase() == 'DIAGNOSTIC' ||
                      newItemName.trim().toUpperCase() == 'AUTOEVALUACIÓN DIAGNÓSTICA' 
                    ) {
                      let myGradeDiag = graderaw === null ? 0 : graderaw.toFixed(2);
                      diagnostic = myGradeDiag
                    }
                  }
                }); // *** End [gradeitems]
                
              }
            });
          }
          
        }); // *** End [dataGrade]
        

        // *** Check hasFilterNote
        if (hasFilterNote) {
          console.log('hasFilterNote')
          switch (operadorFilter) {
              case 'mayor':
                add = currentNote > parseInt(valorFilter) ? true : false;
              break;
              case 'menor':
                add = currentNote < parseInt(valorFilter) ? true : false; 
              break;
              case 'igual':
                add = currentNote == parseInt(valorFilter) ? true : false; 
              break;
          } 
        }

        console.log('add after check has filter note:', add)

        // *** Check Cursos
        if (cursoCurrent != '0') {
          console.log('cursoCurrent ', cursoCurrent)
          if (cursoID != cursoCurrent) {
            console.log('cursoID ', cursoID)
            add = false;
          }
        }

        console.log('add after check cursos:', add)

        // *** Check Secciones
        if (sectionCurrent != '0') {
          console.log('sectionCurrent: ', sectionCurrent)
          if (seccionID != sectionCurrent) {
            console.log('seccionID ', seccionID)
            add = false;
          }
          
        }

        console.log('add after check section:', add)

        // *** OJO En Desarrollo OJO
        // *** Check Partner Start
        //partner = Usuario.myPartner(itemStudent, this.userFullDataWithProductCurrent);
        partner = itemStudent.partner;
        if (partnerSelect != '0') {
          console.log('partnerSelect')
          let flagPartner = true;
          for (const selectPartnerItem of selectPartner) {

            if (flagPartner) {
              console.log(`${selectPartnerItem.nombre} == ${partner}`)
              if (selectPartnerItem.nombre == partner && partnerSelect == selectPartnerItem.id) {
                add = true
                flagPartner = false
              } else {
                add = false
              }
            }

          }
        }

        // if (selectPartnerReportMoodle != '0') {
        //   if (partner != selectPartnerReportMoodle) {
        //     add = false;
        //   }
        // }
        // *** OJO En Desarrollo OJO

        // *** No Actividad
        if (withoutActivity) {
         
          if (currentNote == 0 || currentNote == '0' || currentNote == '') {
            add = false;
          } 
        }

        console.log('add after check not activity:', add)

        if (sectionStartDate != null && sectionStartDate != '' && sectionEndDate != null && sectionEndDate != '') {
          let startDateTemp = new Date(fechaInicioTimeStamp * 1000)
          let monthTemp = startDateTemp.getMonth() + 1;
          monthTemp = ("0" + monthTemp).slice(-2)
          let datebase = new Date('2022-01-01')
          let dayTemp = '';
          if (startDateTemp.getTime() < datebase.getTime()) {
            dayTemp = startDateTemp.getDate();
          } else {
            dayTemp = startDateTemp.getDate();
          }
          dayTemp = ("0" + dayTemp).slice(-2)
          let newStartDateTemp = `${startDateTemp.getFullYear()}-${monthTemp}-${dayTemp}`;
          startDateTemp = new Date(newStartDateTemp);
          let startDateSearch = new Date(sectionStartDate);
          let endDateSearch = new Date(sectionEndDate);
          if (startDateTemp instanceof Date && !isNaN(startDateTemp.valueOf())) {
            if (startDateTemp.getTime() >= startDateSearch.getTime() && startDateTemp.getTime() <= endDateSearch.getTime()) {
              
            } else {
              add = false
            }
          }
        }

        console.log('add after check filter date:', add)

        // *** Add Student ***
        if (add) {
          console.log('Add')  

          const baseData = {
            fullNameStudent: itemStudent.fullname,
            idStudent: itemStudent.id,
            course: curso_current,
            section: seccion_current,
            startDate: fechaInicio,
            final: currentNote,
            diagnostic: diagnostic,
            activity1: activity1,
            activity2: activity2,
            activity3: activity3,
            activity4: activity4,
            autoevaluation1: autoevaluation1,
            autoevaluation2: autoevaluation2,
            autoevaluation3: autoevaluation3,
            autoevaluation4: autoevaluation4,
            partner: partner,
            notes: notes,
            emailStudent: email,
            finalScore: finalScore,
          }

          let mergerBaseDataNote = {}
          
          mergerBaseDataNote = {
            ...baseData,
            //...allNote
          }
          
          console.log('mergerBaseDataNote: ', mergerBaseDataNote)
          dataTableCertificadoTemp.push(mergerBaseDataNote);
        }
        // *** Reset Note by Student
        currentNote = 0;

      }); // *** End [itemSeccionStudent]

  }); // *** End [dataEnrolment]

  dispatch(consultarReporteCalificacionesEnd(dataTableCertificadoTemp));
  // *** Build Table End
}

export const consultarReporteAcceso = (dataEnrolment, dataGrade, datatable, hasFilterNote, valorFilter, operadorFilter, cursoCurrent, sectionCurrent, sectionStartDate, sectionEndDate, withoutActivity, partnerSelect, selectPartner) => dispatch => {

  dispatch(consultarReporteCalificacionesStart());

  let dataTableCertificadoTemp = []

  // *** Build Table Start
  dataEnrolment.forEach( itemSeccionStudent => {
    console.log('itemSeccionStudent')
    console.log(itemSeccionStudent)
      let curso_current = '';
      let seccion_current = '';
      let seccionID = '';
      let cursoID = '';
      let fechaInicio = 0;
      let fechaInicioTimeStamp = null;
      let currentNote = 0;
      let finalNote = 0;
      let diagnostic = 0;
      let activity1 = 0;
      let activity2 = 0;
      let activity3 = 0;
      let activity4 = 0;
      let email = '';
      let lastAccess = '';

      datatable.forEach( cursoItem => {
        cursoItem.secciones.forEach( seccionItem => {
          if (seccionItem.id == itemSeccionStudent.seccion_id) {
            cursoID = cursoItem.curso_id;
            curso_current = cursoItem.curso;
            seccion_current = seccionItem.shortname;
            if (seccionItem.startdate != 0) {
              let formatStartDate =  new Date(seccionItem.startdate * 1000);
              fechaInicioTimeStamp = seccionItem.startdate;
              let datebase = new Date('2022-01-01')
              if (formatStartDate.getTime() < datebase.getTime()) {
                fechaInicio = `${formatStartDate.getDate()}/${formatStartDate.getMonth() + 1}/${formatStartDate.getFullYear()}`; 
              } else {
                fechaInicio = `${formatStartDate.getDate()}/${formatStartDate.getMonth() + 1}/${formatStartDate.getFullYear()}`; 
              }

            }    
            seccionID = seccionItem.id;
          }
        })
      }); // *** End [datatable]

      // ***                ***
      // *** Fill DataTable ***
      // ***                ***

      itemSeccionStudent.students.forEach( itemStudent => {

        console.log('itemStudent')
        console.log(itemStudent)
        const allNote = {}
        let notes = [];
        let add = true;
        finalNote = 0;
        let partner = '';
        const activityArr = []
        email = itemStudent.email
        lastAccess = itemStudent.lastcourseaccess
        if (lastAccess == 0) {
          lastAccess = ''  
        } else {
          let formatLastAccess =  new Date(lastAccess * 1000);
          lastAccess = `${formatLastAccess.getDate()}/${formatLastAccess.getMonth() + 1}/${formatLastAccess.getFullYear()}`;
        }

        dataGrade.forEach( baseDataGrade => {

          if (baseDataGrade.seccion_id == itemSeccionStudent.seccion_id) {
            baseDataGrade.grades.usergrades.forEach( usergrades => {
              if (usergrades.userid == itemStudent.id) {
                
                const { gradeitems } = usergrades;
                
                finalNote = 0; 
                gradeitems.forEach( (element, index) => {
                  const { itemname, itemmodule, grademax, graderaw, id } = element;
                  let newItemName = itemname === null ? '' : itemname.toUpperCase();
                  //newItemName = newItemName.replace(/\s/g, '');

                  if (newItemName !== '') {
                    console.log('element')
                    console.log(element)

                    
                    let myNote = graderaw === null || graderaw === 0 ? '' : graderaw.toFixed(2);
                    allNote[index] = myNote
                    
                    notes.push({
                      id:id,
                      nameItem:newItemName.trim().toUpperCase(),
                      grade: myNote
                    })

                    if (
                      newItemName.trim().toUpperCase() == 'EXAMEN FINAL' ||
                      newItemName.trim().toUpperCase() == 'FINAL ASSESSMENT' ||
                      newItemName.trim().toUpperCase() == 'EXAME FINAL' ||
                      newItemName.trim().toUpperCase() == 'ФИНАЛЬНЫЙ ЭКЗАМЕН' ||
                      newItemName.trim().toUpperCase() == 'امتحان نهائي'
                      ) {
                      
                      let myGrade = graderaw === null ? 0 : graderaw.toFixed(2);
                      currentNote = myGrade;
                    }
                    
                    // *** Activity ***
                    if (
                      itemmodule == 'assign'
                    ) {
                      let myGradeAct = graderaw === null ? 0 : graderaw.toFixed(2);
                      activityArr.push(myGradeAct)
                      
                      console.log('activityArr.length: ', activityArr.length)
                      if (activityArr.length === 1) {
                        activity1 = myGradeAct
                      }
                      if (activityArr.length === 2) {
                        activity2 = myGradeAct
                      }
                      if (activityArr.length === 3) {
                        activity3 = myGradeAct
                      }
                      if (activityArr.length === 4) {
                        activity4 = myGradeAct
                      }
                      
                    }
                    // *** Diagnostic *** 
                    if (
                      newItemName.trim().toUpperCase() == 'DIAGNÓSTICA' ||
                      newItemName.trim().toUpperCase() == 'DIAGNOSTIC'
                    ) {
                      let myGradeDiag = graderaw === null ? 0 : graderaw.toFixed(2);
                      diagnostic = myGradeDiag
                    }
                  }
                }); // *** End [gradeitems]
                
              }
            });
          }
          
        }); // *** End [dataGrade]
        

        // *** Check hasFilterNote
        if (hasFilterNote) {
          console.log('hasFilterNote')
          switch (operadorFilter) {
              case 'mayor':
                add = currentNote > parseInt(valorFilter) ? true : false;
              break;
              case 'menor':
                add = currentNote < parseInt(valorFilter) ? true : false; 
              break;
              case 'igual':
                add = currentNote == parseInt(valorFilter) ? true : false; 
              break;
          } 
        }

        console.log('add after check has filter note:', add)

        // *** Check Cursos
        if (cursoCurrent != '0') {
          console.log('cursoCurrent ', cursoCurrent)
          if (cursoID != cursoCurrent) {
            console.log('cursoID ', cursoID)
            add = false;
          }
        }

        console.log('add after check cursos:', add)

        // *** Check Secciones
        if (sectionCurrent != '0') {
          console.log('sectionCurrent: ', sectionCurrent)
          if (seccionID != sectionCurrent) {
            console.log('seccionID ', seccionID)
            add = false;
          }
          
        }

        

        console.log('add after check section:', add)

        // *** OJO En Desarrollo OJO
        // *** Check Partner Start
        //partner = Usuario.myPartner(itemStudent, this.userFullDataWithProductCurrent);
        partner = itemStudent.partner;
        // *** Check Partner
        if (partnerSelect != '0') {
          console.log('partnerSelect')
          let flagPartner = true;
          for (const selectPartnerItem of selectPartner) {

            if (flagPartner) {
              console.log(`${selectPartnerItem.nombre} == ${partner}`)
              if (selectPartnerItem.nombre == partner && partnerSelect == selectPartnerItem.id) {
                add = true
                flagPartner = false
              } else {
                add = false
              }
            }

          }
        }

        // if (selectPartnerReportMoodle != '0') {
        //   if (partner != selectPartnerReportMoodle) {
        //     add = false;
        //   }
        // }
        // *** OJO En Desarrollo OJO

        // *** No Actividad
        if (withoutActivity) {
         
          if (currentNote == 0 || currentNote == '0' || currentNote == '') {
            add = false;
          } 
        }

        console.log('add after check not activity:', add)

        if (sectionStartDate != null && sectionStartDate != '' && sectionEndDate != null && sectionEndDate != '') {
          let startDateTemp = new Date(fechaInicioTimeStamp * 1000)
          let monthTemp = startDateTemp.getMonth() + 1;
          monthTemp = ("0" + monthTemp).slice(-2)
          let datebase = new Date('2022-01-01')
          let dayTemp = '';
          if (startDateTemp.getTime() < datebase.getTime()) {
            dayTemp = startDateTemp.getDate();
          } else {
            dayTemp = startDateTemp.getDate();
          }
          dayTemp = ("0" + dayTemp).slice(-2)
          let newStartDateTemp = `${startDateTemp.getFullYear()}-${monthTemp}-${dayTemp}`;
          startDateTemp = new Date(newStartDateTemp);
          let startDateSearch = new Date(sectionStartDate);
          let endDateSearch = new Date(sectionEndDate);
          if (startDateTemp instanceof Date && !isNaN(startDateTemp.valueOf())) {
            if (startDateTemp.getTime() >= startDateSearch.getTime() && startDateTemp.getTime() <= endDateSearch.getTime()) {
              
            } else {
              add = false
            }
          }
        }

        console.log('add after check filter date:', add)

        // *** Add Student ***
        if (add) {
          console.log('Add')  

          const baseData = {
            fullNameStudent: itemStudent.fullname,
            idStudent: itemStudent.id,
            course: curso_current,
            section: seccion_current,
            startDate: fechaInicio,
            finalScore: currentNote,
            diagnostic: diagnostic,
            activity1: activity1,
            activity2: activity2,
            activity3: activity3,
            activity4: activity4,
            partner: partner,
            notes: notes,
            emailStudent: email,
            lastAccess: lastAccess,
          }

          let mergerBaseDataNote = {}
          
          mergerBaseDataNote = {
            ...baseData,
            //...allNote
          }
          
          console.log('mergerBaseDataNote: ', mergerBaseDataNote)
          dataTableCertificadoTemp.push(mergerBaseDataNote);
        }
        // *** Reset Note by Student
        currentNote = 0;

      }); // *** End [itemSeccionStudent]

  }); // *** End [dataEnrolment]

  dispatch(consultarReporteCalificacionesEnd(dataTableCertificadoTemp));
  // *** Build Table End
}