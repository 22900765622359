import { OCTAVIO_URL, OCTAVIO_TOKEN } from '../const'
import { fetchParter, fillSelectPartner } from './partnerActions'
import { withProductCurrent } from './usuarioActions'
import { fetchIdioma } from './idiomaActions'

export const FETCH_PRODUCTO_BEGIN   = 'FETCH_PRODUCTO_BEGIN';
export const FETCH_PRODUCTO_SUCCESS = 'FETCH_PRODUCTO_SUCCESS';
export const CHANGE_PRODUCTO_SELECT = 'CHANGE_PRODUCTO_SELECT';

export const fetchProductoBegin = () => ({
  type: FETCH_PRODUCTO_BEGIN
});

export const fetchProductoSuccess = (cliente, productos, arrCompras, arrUser) => dispatch => {

  dispatch({
    type: FETCH_PRODUCTO_SUCCESS,
    payload: { productos, arrCompras, arrUser}
  });

  dispatch(fetchParter(cliente, productos))
  
};


export const fetchProducto = (idcliente) => {
  console.log('fetchProducto')
  return async(dispatch) => {
    dispatch(fetchProductoBegin())
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Auth-Token", OCTAVIO_TOKEN);
    myHeaders.append("cliente", idcliente);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders
    };

    let page = 1;

    const arrProductos = [];

    for (page; page <= 5; page++) {

      let p1 = await fetch(OCTAVIO_URL + `/productos?page=${page}`, requestOptions);
      let p2 = await p1.text();
      let data = JSON.parse(p2)
      console.log('Check Productos')
      console.log(data)

      if (data.length > 0) {
        const dataFilter = data.filter(producto => {
          if (producto.idsMoodle !== null && producto.idsMoodle !== '') {
            return producto;
          }
        })
        arrProductos.push(...dataFilter)
      } else {
        page = 10000000;
      }
    }

    let pageCompras = 1

    const arrCompras = [];

    for (pageCompras; pageCompras <= 1000; pageCompras++) {
      let p1 = await fetch(OCTAVIO_URL + `/compras?page=${pageCompras}&fullCompra=false&disablePagination=false`, requestOptions);
      let p2 = await p1.text();
      let data = JSON.parse(p2)
      console.log('Compras New: ', data)

      if (data.length > 0) {
        for (const dataItem of data) {
          arrCompras.push(dataItem)
        }
      } else {
        pageCompras = 10000000;
      }
    } 


    let pageUser = 1
    const arrUser = [];
    
    for (pageUser; pageUser <= 1000; pageUser++) {
      let p1 = await fetch(OCTAVIO_URL + `/usuarios?page=${pageUser}`, requestOptions);
      let p2 = await p1.text();
      let data = JSON.parse(p2)
      console.log('Users: ', data)

      if (data.length > 0) {
        for (const dataItem of data) {
          arrUser.push(dataItem)
        }
      } else {
        pageUser = 10000000;
      }
    } 

    dispatch(fetchProductoSuccess(idcliente, arrProductos, arrCompras, arrUser))
  }

}


export const changeProductoSelect = (producto, usuarioFullData, cursos, sections) => dispatch => {
  console.log('changeProductoSelect')
  dispatch({
    type: CHANGE_PRODUCTO_SELECT,
    payload: {
      datatable: [],
      secciones: [],
      idiomas: [
        {name: 'ES', value: 'ES'},
        {name: 'EN', value: 'EN'},
        {name: 'PT', value: 'PT'},
        {name: 'AR', value: 'AR'},
        {name: 'RU', value: 'RU'},
      ],
      idiomaCurrent: 0,
      productoCurrent: producto
    }
  })

  if (producto != '0') {
    dispatch(withProductCurrent(producto, usuarioFullData))
    console.log('producto: ', producto)
    console.log('cursos: ', cursos)
    console.log('sections: ', sections)
    dispatch(fetchIdioma(producto, cursos, sections))    
  }

}
