import { OCTAVIO_URL, OCTAVIO_TOKEN } from '../const'
import { fetchProducto } from './productoActions'
import { fetchCurso } from './cursoActions'
import { defaultIdiomaSelect } from './idiomaActions'
import { fetchPartnersClientes } from './partnerActions'

export const FETCH_INSTANCIA_BEGIN   = 'FETCH_INSTANCIA_BEGIN';
export const FETCH_INSTANCIA_SUCCESS = 'FETCH_INSTANCIA_SUCCESS';
export const FETCH_INSTANCIA_FAILURE = 'FETCH_INSTANCIA_FAILURE';
export const CHANGE_INSTANCIA_SELECT = 'CHANGE_INSTANCIA_SELECT';

export const fetchInstanciaBegin = () => ({
  type: FETCH_INSTANCIA_BEGIN
});

export const fetchInstanciaSuccess = instancias => ({
  type: FETCH_INSTANCIA_SUCCESS,
  payload: { instancias }
});

export const fetchInstanciaFailure = error => ({
  type: FETCH_INSTANCIA_FAILURE,
  payload: { error }
});

export const fetchInstancia = () => {

  return dispatch => {
    dispatch(fetchInstanciaBegin())
    let myHeaders = new Headers();
    myHeaders.append('Accept', 'application/json');
    myHeaders.append('Auth-Token', OCTAVIO_TOKEN);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };
    return fetch(OCTAVIO_URL + '/clientes', requestOptions)
    .then(handleErrors)
    .then((response) => response.text())
    .then((result) => {
      let data = JSON.parse(result);
      dispatch(fetchInstanciaSuccess(data))
    })
    .catch((error) => dispatch(fetchInstanciaFailure(error)));
  }

}

const handleErrors = (response) => {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

export const changeInstanciaSelect = instancia => dispatch => {
  console.log('changeInstanciaSelect')
  dispatch({
    type: CHANGE_INSTANCIA_SELECT,
    payload: {instancia}
  })
  dispatch(fetchPartnersClientes(instancia))
  dispatch(fetchProducto(instancia));
  dispatch(fetchCurso(parseInt(instancia)))
  dispatch(defaultIdiomaSelect())
};