import { OCTAVIO_URL, OCTAVIO_TOKEN } from '../const'

export const FETCH_USUARIOWITHPARTNER_BEGIN = 'FETCH_USUARIOWITHPARTNER_BEGIN';
export const FETCH_USUARIOWITHPARTNER_SUCCESS = 'FETCH_USUARIOWITHPARTNER_SUCCESS';
export const USUARIOWITHPRODUCTCURRENT = 'USUARIOWITHPRODUCTCURRENT'

export const fetchUsuarioPartnerBegin = () => ({
  type: FETCH_USUARIOWITHPARTNER_BEGIN
});

export const fetchUsuarioPartnerSuccess = (usuarioFullData) => dispatch => {

  dispatch({
    type: FETCH_USUARIOWITHPARTNER_SUCCESS,
    payload: { usuarioFullData }
  });

};

export const fetchUsuarioPartner = (partner, cliente) => {
  console.log('fetchUsuarioPartner')
  return async(dispatch) => {
    
    dispatch(fetchUsuarioPartnerBegin())
    
    const myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("Auth-Token", OCTAVIO_TOKEN);
      myHeaders.append("cliente", cliente);
      const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
      };
  
      fetch(OCTAVIO_URL + "/usuarios" , requestOptions)
      .then(response => response.text())
      .then(result => {
  
        let data = JSON.parse(result)

        const newPartner = partner.map( element => {
          let newPropsObj = {};
          data.forEach( elementData => {
            
            if (element.usuario === elementData.id) {
              newPropsObj = {
                userFullData:elementData,
              };
            }
          });
          return Object.assign(element, newPropsObj);
        });
        dispatch(fetchUsuarioPartnerSuccess(newPartner))
      })

  }

}

export const usuarioWithProductCurrent = (data) => dispatch => {
  dispatch({
    type: USUARIOWITHPRODUCTCURRENT,
    payload: {usuarioFullDataWithProductCurrent: data}
  })
}


export const withProductCurrent = (currentProducto, data) => dispatch => {
  console.log('withProductCurrent')
  console.log(currentProducto)
  console.log(data)
  const newData = [];

    data.forEach( userDataitem => {

      const parseData = JSON.parse(userDataitem.productos);
      let add = false;
      parseData.forEach(parseDataItem => {
        if (parseDataItem.producto == currentProducto) {
          add = true
        } 
      });

      if (add) {
        newData.push(userDataitem)
      }
    })
    dispatch(usuarioWithProductCurrent(newData))
}
