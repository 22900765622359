import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Navigation from '../Navigation/Navigation';
import SecondaryNavigationEmpty from '../SecondaryNavigation/SecondaryNavigationEmpty';

class Escritorio extends Component {

    render() {
        return (
            <div>
                <Navigation />
                <SecondaryNavigationEmpty title="Escritorio" />
                <div className="container mt-4">
                    <div className="row">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                                    <div className="card shadow-sm border-0 w-100">
                                        <div className="card-body p-5">
                                            <h5 className="card-title">Aplicaciones</h5>
                                            <hr />
                                            <div className="d-fle align-items-center justify-content-between">
                                                <Link to="/certificados">
                                                    <button type="button" className="btn btn-secondary mr-2 mb-2">
                                                        Certificados
                                                    </button>
                                                </Link>
                                                <Link to="/templates">
                                                    <button type="button" className="btn btn-secondary mr-2 mb-2">
                                                        Templates
                                                    </button>
                                                </Link>
                                                <Link to="/certificados-aprobacion-verificado">
                                                    <button type="button" className="btn btn-secondary mr-2 mb-2">
                                                    Certificados de aprobación Verificado
                                                    </button>
                                                </Link>
                                                <Link to="/asistencias">
                                                    <button type="button" className="btn btn-secondary mr-2 mb-2">
                                                        Carga de Asistencias
                                                    </button>
                                                </Link>
                                                <Link to="/usuarios">
                                                    <button type="button" className="btn btn-secondary mr-2 mb-2">
                                                        Usuarios
                                                    </button>
                                                </Link>
                                                <Link to="/openai">
                                                    <button type="button" className="btn btn-secondary mr-2 mb-2">
                                                        Open Ai
                                                    </button>
                                                </Link>
                                                <Link to="/reportes">
                                                    <button type="button" className="btn btn-secondary mr-2 mb-2">
                                                        Carga de reportes
                                                    </button>
                                                </Link>
                                                <Link to="/productos">
                                                    <button type="button" className="btn btn-secondary mr-2 mb-2"> Enrolment
                                                        {/* Enrolment <span className="badge badge-light">Nuevo!</span> */}
                                                    </button>
                                                </Link>
                                                <Link to="/reprogramacion">
                                                    <button type="button" className="btn btn-primary mr-2 mb-2">
                                                        Reprogramaciones <span className="badge badge-light">Nuevo!</span>
                                                    </button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-8 col-lg-8 col-md-6 col-sm-6 col-12">
                                    <div className="card shadow-sm border-0 w-100">
                                        <div className="card-body p-5">
                                            <h5 className="card-title">Estadísticas</h5>
                                            <hr />
                                            <div className="d-fle align-items-center justify-content-between">
                                                <p>No hay elementos para mostrar</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }

}

export default Escritorio;